/* recolor: 587B9D 78A5DF 879CB5 537393 6086AB DAE2EC EDF2F7 4C7299 5D83C7 DBE7F7 6A94B7 E1E7ED C3D7EB 6A93B7 D0E8F8 B3BFFF 2F8DD5 */
/*Includes*/
/*Reset*/
* { margin: 0; padding: 0; border: 0 none; background: none; }

html, body { width: 100%; height: 100%; margin: 0; }

input[type='reset']::-moz-focus-inner, input[type='button']::-moz-focus-inner, input[type='submit']::-moz-focus-inner, input[type='file'] > input[type='button']::-moz-focus-inner { padding: 0; border: 0; }

/*Old main styles*/
a { color: #537393; }

ul li { list-style: none; }

.band-graphic svg { overflow: inherit !important; }

#wrapper-mainmenu { position: relative; }

/*top menu*/
#top_panel { position: relative; height: 69px; padding-right: 4px; }

#top_panel .bottom-line { position: absolute; z-index: 1; bottom: -2px; width: 100%; height: 2px; background: #E1E7ED; }

.tabscrollactive #top_panel .bottom-line-right { position: absolute; z-index: 2; right: 0; bottom: -2px; width: 17px; height: 2px; background: none repeat scroll 0 0 #FFFFFF; }

/*#top_panel ul.inline-menu li */
.b-top-links__item { line-height: 20px; display: inline-block; zoom: 1; padding-right: 16px; *display: inline; }

.b-top-links__item_is_label { padding-right: 0; }

.b-top-links__item_trial { color: red; }

#top_panel ul.inline-menu li.users { padding-right: 0; }

#top_panel ul.inline-menu li.levels { padding-left: 0; }

#top_panel ul.inline-menu div.s-icon { margin-right: 1px; margin-bottom: -4px; }

#top_panel ul.inline-menu li.levels div.s-icon { margin-right: 4px; }

.sepr-users { padding-left: 13px; background: url(../img/arrow-small.png) no-repeat 7px 4px; }

#topLinks { padding-top: 5px; padding-left: 205px; }

#logo { position: absolute; top: 0; left: 0; width: 187px; height: 70px; }

/*topBarWrapper*/
#topBarWrapper { position: absolute; z-index: 10; top: 0; left: 0; background: #FFFFFF; }

#cbutton a { display: block; width: 0; height: 0; margin-top: 24px; margin-left: -10px; border-top: 7px solid #E1E7ED; border-bottom: 7px solid #E1E7ED; border-left: 6px solid #FFFFFF; }

.active-menu #cbutton { display: none; }

#cbutton { position: absolute; z-index: 10; top: 50%; right: -10px; width: 0; height: 65px; margin-top: -47px; cursor: pointer; border-top: 14px outset transparent; border-bottom: 14px outset transparent; border-left: 14px solid #E1E7ED; }

/*main menu*/
#left { position: absolute; z-index: 11; top: 69px; left: -203px; width: 205px; background: #FFFFFF; }

.active-menu #left { left: 0; }

#menu-items-wr { padding-right: 18px; }

#h69 { height: 69px; }

#mainmenu { position: relative; padding-left: 8px; }

#left #mainmenu .group li a.h-group { font-size: 12px; font-weight: bold; text-decoration: none; color: #587B9D; }

#left #mainmenu .group li li a.textlink { width: 124px; }

.group li { user-select: text; }

.collapsed a.h-group { display: inline-block; zoom: 1; position: relative; padding-right: 10px; *display: inline; }

.collapsed a.h-group:after { position: absolute; right: 0px; width: 4px; height: 14px; background: url(../img/triangle.png) no-repeat right center; content: ''; }

.b-hgroup__inner { white-space: nowrap; overflow: hidden; text-overflow: ellipsis; display: inline-block; max-width: 150px; }

.collapsed .b-hgroup__inner { max-width: 140px; }

.b-group__li { white-space: nowrap; }

.search .collapsed a.h-group { background: none; }

#left #mainmenu .group li li { vertical-align: middle; }

#left #mainmenu .group li li a { font-size: 11px; font-weight: normal; line-height: 17px; position: relative; display: inline-block; overflow: hidden; zoom: 1; vertical-align: top; white-space: nowrap; text-decoration: none; text-overflow: ellipsis; color: #537393; *display: inline; }

#left #mainmenu .group li li.overwidth span.more { font-size: 11px; line-height: 17px; display: inline-block; zoom: 1; vertical-align: top; *display: inline; }

#left #mainmenu .group li li .m-item-newtab-icon { display: inline-block; zoom: 1; width: 11px; height: 17px; vertical-align: top; background: url("../img/open_newtab.png") left center no-repeat; *display: inline; }

#left #mainmenu .group li li:hover span.more { color: #FFFFFF; }

#left #mainmenu .group li li span.more { display: none; }

#left #mainmenu .group li li.active a { text-decoration: none; color: #000000; }

#left #mainmenu .group li li:hover { width: 85%; border-radius: 3px; background: #6A94B7; }

#left #mainmenu .group li li.loading { width: 85%; border-radius: 3px; }

#left #mainmenu .group li li:hover a, #left #mainmenu .group li li.loading:hover a, #left #mainmenu .group li li.loading a { color: #FFFFFF; }

#left #mainmenu .group li { list-style: none; }

#left #mainmenu ul li { padding-left: 0; list-style-position: inside; }

#left #mainmenu ul li li { line-height: 17px; position: relative; height: 17px; padding-left: 21px; }

#mainmenu ul ul { margin-top: 6px; margin-left: 0; }

#mainmenu ul { margin-bottom: 20px; margin-left: 5px; }

#mainmenu .group img, #mainmenu .group div.s-icon { float: left; width: 16px; height: 16px; margin-top: -2px; margin-right: 5px; }

#mainmenu .group .m-item img, #mainmenu .group .m-item div.s-icon { float: inherit; width: inherit; height: inherit; margin: inherit; vertical-align: middle; }

#mainmenu .group .m-item a.newtab { position: absolute !important; left: 150px; visibility: hidden; }

#mainmenu .group .m-item:hover a.newtab { visibility: visible; }

#mainmenu #m-choose img, #mainmenu #m-choose div.s-icon { width: 16px; height: 16px; margin: 2px; }

.active-menu #mainmenu #m-choose #mfb { background: none repeat scroll 0 0 #DAE2EC; }

#mainmenu #m-choose #minus a { margin-right: 8px; }

#mainmenu #m-choose .m-choose-left { float: left; }

#mainmenu #m-choose .m-choose-right { float: right; }

.filter .triangle-down { font-size: 0; line-height: 0; width: 0; height: 0; margin: 7px auto; border-top: 4px solid #879CB5; border-right: 4px solid #E1E7ED; border-left: 4px solid #E1E7ED; }

.filter-hide.filter .toolbar-wrapper .triangle-down, .triangl-right { font-size: 0; line-height: 0; width: 0; height: 0; margin: 4px auto; border-top: 4px solid #E1E7ED; border-bottom: 4px solid #E1E7ED; border-left: 4px solid #879CB5; }

.filter-hide.filter .toolbar-wrapper .triangle-down { margin: 6px 9px; }

.filter .toolbar-wrapper .triangle-down { margin: 9px auto; }

.active-menu #scrollbar-track { display: block; }

#left:hover #scrollbar-track { display: block; }

#m-choose a { display: inline-block; zoom: 1; width: 20px; height: 20px; margin-right: 8px; text-align: center; vertical-align: middle; border-radius: 3px; *display: inline; }

#m-choose .m-choose-right a { margin-right: 0; }

#m-choose a#mfb { width: 20px; height: 20px; margin-right: 0; }

#mainmenu #m-choose { width: 168px; height: 16px; margin-bottom: 12px; padding-left: 2px; }

.search #menu-items { margin-top: 0 !important; }

.collapsed ul { display: none; }

/*search block*/
#m-search-wrapper { position: relative; width: 170px; margin-bottom: 17px; }

#m-search { width: 140px; height: 20px; padding-left: 23px; border: 1px solid #C3C3C3; border-radius: 2px; background-image: url("../img/sprite-icons.png"); background-repeat: no-repeat; background-position: 5px -213px; }

#m-search.default-text { color: #CCCCCC; }

.search #m-search.default-text { color: #000000; }

#close-search { position: absolute; top: 5px; right: 5px; }

.not_active { display: none; }

.b-search-close { width: 11px; height: 11px; display: inline-block; background-image: url("../img/sprite-icons.png"); background-repeat: no-repeat; background-position: 0px -185px; }

.vertical-scroll #copyright { position: relative; margin-top: 25px; }

#copyright { position: absolute; bottom: 8px; text-align: center; width: 170px; }

#menu-items { padding-bottom: 30px; }

.search .collapsed ul { display: block; }

.search ul.group { display: none; }

.search #menu-items ul.group.cont { display: block; }

#menu-items.popular .unpopular { display: none; }

.search .popular .unpopular { display: block; }

#menu-items.favorite .unfavorite { display: none; }

.search .favorite .unfavorite { display: block; }

.search #menu-items .m-item.popular, .search #menu-items .m-item.unpopular, .search #menu-items .m-item.favorite, .search #menu-items .m-item.unfavorite { display: block; }

.search #menu-items .m-item.s-hidden { display: none; }

#cont-tab0.hidden { display: block !important; visibility: hidden; }

.ie8 #cont-tab0.hidden { display: none !important; }

#cont-tab0.hidden .dash-table table.list_table thead { visibility: hidden; }

.b-form-page_view_dblock.b-form-page_st_collapsed table.list_table thead { visibility: hidden; }

.hidden, .s-hidden, .search .group .s-hidden { display: none; }

div.with-menu { margin-left: 205px; }

.active.menev { background: #DAE2EC; }

#menu-fix-btn #mfb { display: block; width: 10px; height: 10px; background: #CCCCCC; }

.active-menu #menu-fix-btn #mfb { background: #000000; }

#menu-fix-btn { float: left; }

#m-search.not_found { background-color: #FFE3D8; }

a.cont { color: red !important; }

.inline-menu { text-align: right; }

/*tabs*/
#tabs { position: absolute; z-index: 2; bottom: -3px; left: 204px; height: 27px; white-space: nowrap; }

#tabs-wr { position: relative; }

.vhidden { visibility: hidden; }

.tabscrollactive #all-tabs { padding: 0 25px; }

ul.nav-tabs li { display: inline-block; zoom: 1; height: 17px; margin-right: 5px; padding-bottom: 8px; list-style-image: none; border: 1px solid #E1E7ED; border-bottom: 0 none; border-radius: 12px 12px 0 0; background: #E1E7ED; *display: inline; vertical-align: top; }

.tab-group_moving_yes { z-index: 99; }

.tab-group__shadow { opacity: 0.5; }

ul.nav-tabs li.active a { color: #747373; }

ul.nav-tabs li a.active { cursor: default; text-decoration: none; }

#dashboard-tab .subtab { padding-right: 12px; padding-left: 12px; }

#tabs .subtab.first { padding-left: 12px; }

#tabs .subtab { display: inline-block; zoom: 1; height: 12px; padding-top: 6px; padding-right: 10px; padding-bottom: 8px; padding-left: 8px; border-radius: 12px 12px 0 0; background: #E1E7ED; *display: inline; }

#add-tab { display: none; }

#tabs #add-tab .subtab { padding-right: 9px; padding-left: 9px; }

#tabs .subtab.switch.active .short { display: none; }

#tabs .subtab.switch .short { display: inline; }

#tabs .subtab.switch.active .full { display: inline; }

#tabs .subtab.switch .full { display: none; }

#tabs .subtab.switch .subtab__text_for_pin { display: none; }

#tabs .subtab.switch.subtab_is_pin .subtab__text_for_pin { display: inline-block; margin-right: 3px; }

#tabs .subtab.switch.subtab_is_pin .full, #tabs .subtab.switch.subtab_is_pin .short { display: none; }

#tabs #add-tab .plus-wrapper { position: relative; display: inline-block; zoom: 1; width: 10px; height: 10px; *display: inline; }

#tabs #add-tab .plus-wrapper .vert { position: absolute; left: 50%; width: 2px; height: 10px; margin-left: -1px; background: #879CB5; }

#tabs #add-tab .plus-wrapper .horz { position: absolute; top: 50%; width: 10px; height: 2px; margin-top: -1px; background: #879CB5; }

#tabs .subtab.active, #tabs #dashboard-tab .active .subtab { background: #FFFFFF; }

#tabs #dashboard-tab .subtab { padding-right: 20px; padding-left: 20px; }

.tabs-box { position: relative; display: inline-block; overflow: hidden; zoom: 1; white-space: nowrap; *display: inline; }

.tab-mover { position: absolute; width: 17px; height: 24px; background: #E1E7ED; }

.tabscrollactive #tab-mover-left, .tabscrollactive #tab-mover-right { display: block; }

#tab-mover-right-empty { position: fixed; z-index: 15; top: 45px; right: 17px; width: 8px; height: 24px; background: #FFFFFF; }

.tabscrollactive #tab-mover-left-empty { position: absolute; z-index: 15; left: 17px; width: 8px; height: 24px; background: #FFFFFF; }

#tab-mover-left { position: absolute; z-index: 15; top: 0; left: 0; display: none; }

#tab-mover-right { position: fixed; z-index: 988; top: 45px; right: 0; display: none; }

#tmr-triangle { font-size: 0; line-height: 0; width: 0; height: 0; margin-top: 9px; margin-left: 7px; border-top: 4px solid #E1E7ED; border-bottom: 4px solid #E1E7ED; border-left: 4px solid #879CB5; }

#tml-triangle { font-size: 0; line-height: 0; width: 0; height: 0; margin-top: 9px; margin-left: 7px; border-top: 4px solid #E1E7ED; border-right: 4px solid #879CB5; border-bottom: 5px solid #E1E7ED; }

.sepr { padding: 0 5px; background: url(arrow-big.png) no-repeat 0 3px; }

.switch.active { font-weight: bold; }

#tab-cont { display: inline-block; zoom: 1; white-space: nowrap; *display: inline; }

#tabs a, #topLinks a { text-decoration: none; display: inline-block; vertical-align: top; }

.b-tab-close { margin-left: 5px; vertical-align: middle; width: 11px; height: 11px; display: inline-block; background-image: url("../img/sprite-icons.png"); background-repeat: no-repeat; background-position: 0px -122px; }

.b-tab-close:hover { background-position: 0px 0px; }

.subtab_is_pin .b-tab-close { display: none; background-image: url("../../common/img/tab-pin.png"); background-repeat: no-repeat; background-position: 0 0; width: 11px; height: 11px; cursor: default; }

.bottom-bar { font-weight: bold; line-height: 27px; position: absolute; bottom: 0; width: 100%; height: 27px; margin-left: -20px; padding-left: 20px; color: #4F4F4F; background: #EDF2F7; }

.bottom-bar .statusbar .ovf { padding-left: 14px; }

/*toolbar*/
.toolbar-wrapper { position: relative; min-height: 48px; padding-right: 16px; }

.toolbar-wrapper .toolbar { overflow-x: hidden; white-space: nowrap; }

.toolbar-button.invisible { display: none; }

.toolgrp { display: inline-block; zoom: 1; margin-left: 16px; *display: inline; }

.toolgrp.ind0 { margin-left: 0; }

.toolgrp-control { display: none; }

.toolgrp.collapsed .toolgrp-control { position: absolute; top: 6px; right: 3px; display: inline-block; zoom: 1; *display: inline; }

.toolgrp.collapsed .triangledown { margin-top: 12px; margin-left: 1px; border-right: 4px solid #FFFFFF; border-left: 4px solid #FFFFFF; }

.toolgrp.collapsed .toolbar-button.active:hover .triangledown { border-right: 4px solid #E1E7ED; border-left: 4px solid #E1E7ED; }

.toolgrp.collapsed:hover .toolgrp-bag { position: absolute; z-index: 2; display: block; border: 1px solid #EAF0F6; border-top: 0; border-radius: 0 0 7px 7px; background: #FFFFFF; }

.toolgrp.collapsed:hover .toolgrp-bag .toolbar-button { display: block; }

.toolgrp.collapsed .toolgrp-bag { display: none; }

.toolgrp-bag { display: inline-block; zoom: 1; *display: inline; }

.toolbar-button { position: relative; display: inline-block; zoom: 1; min-width: 50px; vertical-align: bottom; *display: inline; }

.toolbar-button span, .toolbar-button a { color: #4B4B4B; cursor: pointer; display: block; text-align: center; text-decoration: none; user-select: text; }

div.toolbar-button.active:hover { border-radius: 4px; background: #E1E7ED; }

.collapsed div.toolbar-button.active.ind1:hover { border-radius: 0 0 4px 4px; }

.o-button .btntext { display: none; }

.toolbar-button .img-link { padding: 5px 5px 0 5px; }

.o-button .toolbar-button .img-link { padding: 5px; }

.toolbar-button .btntext { padding: 0 5px 5px 5px; }

.toolbar-button.notActive span { opacity: .5; filter: alpha(opacity=50); }

.toolbar-button.removed, .toolgrp.collapsed:hover .toolgrp-bag .toolbar-button.removed { display: none; }

.toolbar-button.notActive span { cursor: default; }

.toolbar-button-toolsep { display: inline-block; zoom: 1; padding: 5px; *display: inline; }

.leftBar { padding-top: 15px; padding-bottom: 5px; padding-left: 7px; vertical-align: middle; }

.leftBar h2 { padding-top: 15px; color: #676666; display: inline-block; }

.leftBar img, .leftBar div.s-icon { margin-right: 8px; margin-bottom: 5px; }

.leftBar .reload { cursor: pointer; }

.breadcrumb { text-decoration: none; color: #676666; }

.breadcrumb:hover { text-decoration: underline; }

.rightBar { float: right; padding-top: 30px; padding-right: 16px; position: relative; z-index: 1; }

.rightBar a { display: inline-block; zoom: 1; margin-left: 10px; *display: inline; }

.tsearch { position: relative; float: right; margin-top: 20px; padding-left: 10pxg; }

.tsearch .itsearch { padding-left: 24px; background: url(../img/i-search.png) no-repeat 3px center; }

.dib.search { position: relative; margin-right: 36px; }

.close_tsearch { position: absolute; top: 4px; right: 4px; visibility: hidden; }

.livesearch .close_tsearch { visibility: visible; }

.fake-row { height: 22px; }

.filter-btn-wrapper { position: absolute; top: 0; right: 0; width: 150px; text-align: right; }

@media (max-width: 1200px) { .filter-btn-wrapper { width: 500px; } }

.filter-btn-wrapper .filter-btns .b-button { font-size: 11px; line-height: 11px; min-width: 97px; margin-bottom: 5px; vertical-align: top; }

.filter-active { display: none; }

.filter-hide .filter-active { display: block; }

.b-button__filter-save-name { font-size: 9px; font-weight: bold; max-width: 90px; text-overflow: ellipsis; white-space: nowrap; overflow: hidden; }

.b-filter__save-bar { display: none; margin-right: 46px; position: relative; text-align: right; white-space: nowrap; overflow: hidden; }

.b-filter__save-bar_has_items { display: block; }

.filter-hide .b-filter__save-bar_has_items { display: none; }

.b-filter__save-bar-inner { display: inline-block; }

.b-filter__btn-move { display: none; width: 20px; height: 20px; position: absolute; background: #E1E7ED; z-index: 1; border: 1px solid #879CB5; }

.b-filter__btn-move_dir_left { left: 0; }

.b-filter__btn-move_dir_left:after { font-size: 0; line-height: 0; width: 0; height: 0; border-top: 9px solid #E1E7ED; border-right: 9px solid #879CB5; border-bottom: 9px solid #E1E7ED; content: ''; margin-right: 6px; }

.b-filter__btn-move_dir_right { right: 0; }

.b-filter__btn-move_dir_right:after { font-size: 0; line-height: 0; width: 0; height: 0; border-top: 9px solid #E1E7ED; border-bottom: 9px solid #E1E7ED; border-left: 9px solid #879CB5; content: ''; margin-right: 4px; }

.b-filter__saved-item { position: relative; display: inline-block; margin-right: 5px; padding-bottom: 3px; padding-right: 6px; padding-top: 4px; padding-left: 10px; text-align: left; color: #64819F; border-radius: 15px; background: #E1E7ED; }

.b-filter__saved-item_st_active { border: 1px solid #64819F; }

.b-filter__saved-item-inner { position: relative; display: inline-block; overflow: hidden; min-width: 35px; }

.b-filter__saved-name { line-height: 11px; display: inline-block; visibility: visible; padding-right: 5px; cursor: pointer; font-weight: bold; }

.b-filter__saved-name-edit { position: absolute; top: 0; left: 0; display: block; visibility: hidden; width: 100%; height: 12px; padding: 0; color: #64819F; border: none; background: transparent; font-size: 11px; font-weight: bold; }

.b-filter__saved-name-edit_editing { visibility: visible; }

.b-filter__saved-name-edit:focus { box-shadow: none; }

.b-filter__saved-remove { display: inline-block; width: 11px; height: 11px; cursor: pointer; background: url(../img/tab_close.png) no-repeat 0 center; user-select: none; }

.b-filter__saved-edit { margin-right: 3px; display: inline-block; width: 12px; height: 12px; cursor: pointer; background: url(../img/filter-edit.png) no-repeat 0 center; }

.b-filter-flag_editing .b-filter__saved-name-edit { visibility: visible; }

.b-filter-flag_editing .b-filter__saved-name { visibility: hidden; }

.b-filter__toggle { background-image: url("../img/filter-open.png"); background-repeat: no-repeat; background-position: 5px 8px; position: absolute; right: 0; width: 24px; height: 62px; display: none; cursor: pointer; border-radius: 12px 12px 0 0; margin-top: -6px; }

.b-filter__toggle:hover, .filter-hide.filter .b-filter__toggle:hover { background-color: #E1E7ED; border-radius: 4px; }

.filter-opened.filter .b-filter__toggle:hover, .filter-set.filter .b-filter__toggle:hover { background-color: #E1E7ED; border-radius: 12px 12px 0 0; }

.filter .b-filter__toggle { background-color: #E1E7ED; display: inline-block; zoom: 1; *display: inline; }

.filter-hide.filter-set.filter .b-filter__toggle { background-color: #E1E7ED; height: 62px; }

.filter-hide.filter .b-filter__toggle { background-color: #FFFFFF; height: 20px; border-radius: 12px; user-select: none; margin-top: 0px; background-position: 5px 2px; }

.filter-set.filter .b-filter__toggle { background-position: 5px 8px; margin-top: -6px; }

.dib { display: inline-block; zoom: 1; *display: inline; }

.filter-active { min-height: 29px; margin-top: 5px; margin-right: 16px; margin-bottom: 10px; padding: 7px 0 2px 12px; border-radius: 12px 0 12px 12px; background: #E1E7ED; }

.filter-active .filter-btns .b-button { font-size: 11px; line-height: 11px; margin-left: 5px; }

.filter-active .filter-msg { font-size: 11px; line-height: 25px; margin-right: 230px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; }

.filter-active .head-filter-msg { font-weight: bold; color: #868A8D; }

.filter-box { display: inline-block; zoom: 1; margin-right: 60px; vertical-align: top; *display: inline; }

.filter-col { padding-right: 60px; vertical-align: top; }

.filter-first { text-align: left; }

.filter-first { min-width: 120px; }

.filter-second { text-align: right; }

.filter-item { margin-bottom: 10px; text-align: right; }

.filter-inner-inner { position: relative; padding-right: 60px; }

@media (max-width: 1200px) { .filter-inner-inner { padding-top: 30px; padding-right: 10px; } }

.filter-item__table { width: 100%; }

.error-message .message { margin-bottom: 10px; }

/*Tab content*/
.statusbar { width: 100%; }

/*table*/
.content { padding-right: 16px !important; }

.content table { width: 100%; border-collapse: collapse; }

.back-btn-row { height: 16px; margin: 2px; cursor: pointer; background: url("../img/back.gif") no-repeat 11px 0; }

.back-btn-row-hint { width: 30px; height: 16px; }

.content table th, .sort_table td, .list_table th { text-align: left; color: #6086AB; }

.content table thead tr, .sort_table td, .list_table th { border-right: 1px; background: #EDF2F7; }

.list_table .data-wrapper, .sort_table .data-wrapper { padding-right: 0; }

.align-center { text-align: center; }

.align-left { text-align: left; }

.align-right { text-align: right; }

.data-wrapper.align-right { padding-right: 14px; }

.ovftd { overflow: hidden; width: 100%; white-space: nowrap; }

.sort_table td { font-weight: bold; }

.content table th { font-weight: bold; }

.b-list__table-col-content_type_link { color: #537393; cursor: pointer; }

.selected .b-list__table-col-content_type_link { color: #FFFFFF; }

.b-list__table-col-content_style_underline { text-decoration: underline; }

table.sort_table { position: absolute; width: 100%; border-spacing: 0; }

.tab-content.init table.sort_table { visibility: hidden; }

.tab-content.init .bottom-bar table.statusbar { visibility: hidden; }

table.list_table { border-spacing: 0; }

.band-content table.sort_table, .dash-table table.sort_table { display: none; }

.band-content table.list_table thead, .dash-table table.list_table thead { visibility: visible; }

.report-nodata { font-size: 14px; padding: 5px 10px; }

.list-content table.sort_table { position: fixed; }

.table-manip .list-content table.sort_table { position: absolute; }

table.list_table thead { visibility: hidden; }

.tab-content.init table.list_table thead { visibility: visible; }

table tr.even { background: #EDF2F7; }

.livesearch-active table tr.even { background: inherit; }

.livesearch-active table tr.lf-odd { background: #EDF2F7; }

table tr.filtred { display: none; }

table.list_table tr.yellow td { background: #FFFF99; }

table.list_table tr.red td { background: #E58440; }

table.list_table tr.green td { background: #00A676; }

table.list_table tr.blue td { background: #4992CD; }

table.list_table tr.magenta td { background-color: purple; }

table.list_table tr.brown td { background-color: brown; }

table.list_table tr.gray td { background-color: gray; }

table.list_table tr.cyan td { background: #1FB2B0; }

table.list_table tr.selected td { color: #FFFFFF; background: #6A93B7; }

.sort_table td, .list_table th { font-weight: bold; line-height: 25px; height: 25px; text-align: left; vertical-align: top; }

.band-table .list_table .rowHandler, .dash-table .rowHandler { height: 25px; cursor: default; }

.rowHandler { position: absolute; top: 0; float: left; width: 2px; height: 100%; cursor: e-resize; }

.tab-content.init .list_table .rowHandler { height: 25px; }

.rowHandler .rowLine1 { float: left; width: 1px; height: 100%; background: #BFBFBF; }

.rowHandler .rowLine2 { float: left; width: 1px; height: 100%; background: #FFFFFF; }

.rowHandler.active { height: 100%; }

.ovf { overflow: hidden; white-space: nowrap; }

table.sort_table { user-select: none; }

table.sort_table td { user-select: text; }

.sort_table .triangle.triangl-top, .list_table .triangle.triangl-top, .triangledown { font-size: 0; line-height: 0; display: inline-block; zoom: 1; width: 0; height: 0; margin-left: 5px; border-top: 4px solid #879CB5; border-right: 4px solid #EDF2F7; border-left: 4px solid #EDF2F7; *display: inline; }

.sort_table .triangl-bot.triangle, .list_table .triangl-bot.triangle { font-size: 0; line-height: 0; display: inline-block; zoom: 1; width: 0; height: 0; margin-left: 5px; border-right: 4px solid #EDF2F7; border-bottom: 4px solid #879CB5; border-left: 4px solid #EDF2F7; *display: inline; }

.sort_table .filter-icon, .list_table .filter-icon { display: none; width: 10px; height: 9px; margin-left: 5px; vertical-align: middle; background-image: url("../img/sprite-icons.png"); background-repeat: no-repeat; background-position: 0px -93px; }

.sort_table .filter-icon.active { display: inline-block; zoom: 1; *display: inline; }

.sort_table .triangle, .list_table .triangle { display: none; }

table .data-wrapper { line-height: 22px; min-height: 22px; padding-right: 14px; padding-left: 14px; vertical-align: middle; white-space: nowrap; *height: 22px; }

table.list_table td .data-wrapper { display: block; overflow: hidden; white-space: nowrap; }

.dash-table .b-list_overflow_ellipsis.list_table { table-layout: fixed; }

.b-list_overflow_ellipsis .b-list__table-row .data-wrapper { text-overflow: ellipsis; }

table.list_table td .ovf .data-wrapper { display: inline; }

.td_sort, .td_client_sort { cursor: pointer; }

.b-list__table_type_footer .td_sort { cursor: default; }

.b-sort-table__sort-order { display: inline-block; font-size: 8px; padding: 0 2px; }

.reload-wrapper { float: right; }

img.props, div.s-icon.props { margin-right: 3px; vertical-align: middle; }

div.controlprop { cursor: pointer; }

.selected .status-red, .selected .status-green, .selected .status-blue, .selected .status-yellow, .selected .status-cyan, .selected .status-brown, .selected .status-gray, .selected .status-magenta { color: #FFFFFF; }

.status-red { color: #E5693C; }

.status-green { color: #00A656; }

.status-blue { color: #4179CD; }

.status-yellow { color: #BFA136; }

.status-gray { color: gray; }

.status-brown { color: brown; }

.status-magenta { color: purple; }

.status-cyan { color: #00B0B2; }

.used { height: 3px; background: #FFA5A5; }

.used-wr { width: 100%; margin-top: -3px; }

.live-search-highlighted, .highlight { color: #000000; background: #FFFF00; }

/*table list pager*/
.pager-wrapper { margin-top: 5px; margin-bottom: 7px; }

.pager-wrapper .pager_list { float: right; margin-right: 17px; }

.pager-list__item { line-height: 18px; display: inline-block; zoom: 1; padding: 0 4px; cursor: pointer; text-align: center; text-decoration: none; color: #4C7299; *display: inline; }

.pager-list__item_active { cursor: default; text-decoration: none; color: #000000; border-radius: 5px 5px 0 0; background: #E1E7ED; }

.pager-list__item_active { cursor: default; text-decoration: none; color: #000000; border-radius: 0 0 5px 5px; background: #E1E7ED; }

.pager-list__item.ellipsis { text-decoration: none; color: #000000; }

.pager-wrapper .slist { float: left; }

.pager-wrapper .page_cnt { margin-left: 230px; }

.pager-wrapper .page_cnt .page_cnt_lb_began { margin-right: 7px; }

.pager-wrapper .page_cnt .page_cnt_lb_end { margin-right: 3px; margin-left: 3px; }

.sb-result, .sb-selected, .sb-result .no, .sb-selected .no { display: none; }

.sb-result_st_active, .sb-selected_st_active { display: block; }

/*Filter*/
.filter-wrapper .filter-title { font-size: 13px; font-weight: bold; margin-bottom: 10px; color: #868A8D; }

.filter-cont { display: inline-block; zoom: 1; *display: inline; }

.filter-cont label { margin-right: 10px; }

.filter-cont td { padding-bottom: 5px; }

.filter-active .filter-btns { float: right; }

.filter-input { position: relative; }

.filter .filter-wrapper { min-height: 100px; margin-top: 5px; margin-right: 16px; margin-bottom: 10px; padding: 12px 0 2px 12px; border-radius: 12px 0 12px 12px; background: #E1E7ED; }

.filter-hide .filter-wrapper { display: none; }

.filter-opened .filter-wrapper, .filter-opened .b-filter__save-bar { z-index: 21; position: relative; }

/*deviewer start*/
#deviewer { position: fixed; z-index: 120; top: 0; left: 300px; }

#deviewer .msg { font-size: 12px; text-align: center; min-height: 14px; padding: 5px 15px; padding-right: 7px; border-bottom: 1px solid #FF8356; border-bottom-right-radius: 10px; border-bottom-left-radius: 10px; color: #FFF; background-color: #FF8356; }

#deviewer .msginner { display: inline-block; color: #FFF; }

#deviewer .b-warning-banner_has_action { text-decoration: underline; cursor: pointer; }

.msgclose { width: 11px; height: 11px; margin-left: 7px; cursor: pointer; vertical-align: middle; display: inline-block; }

/*deviewer end*/
.clearBoth { clear: both; }

.fleft { float: left; }

.fright { float: right; }

/*Loader*/
#loader { position: fixed; top: 50px; left: 187px; display: none; }

#loader.show { display: block; }

.l-form__table tr.hidden { height: 0; }

.middle { margin-left: 5px; vertical-align: middle; }

.bottom { vertical-align: middle; }

select { font-family: Arial; font-size: 11px; padding: 0 7px; border: 1px solid #CCCCCC; border-radius: 4px; background: #FFFFFF; }

.filter-btns .b-button:hover { background: #C6C9CC; }

.filter-btns .b-button.b-button_st_disabled:hover { background: #D4D7DB; }

.filter-btns .b-button:active { background: #B7B9BD; }

/*.button,*/
.filter-btns { position: relative; display: inline-block; zoom: 1; border-radius: 7px; *display: inline; }

.form-item { margin-bottom: 3px; }

.desc { display: inline-block; zoom: 1; padding-top: 2px; padding-left: 5px; vertical-align: top; *display: inline; }

.dash-block-bg { margin-bottom: 35px; }

.b-form-page_st_collapsed .dash-block-bg-header { padding-right: 31px; border-radius: 12px; }

.dash-block-bg-header { padding: 0 15px; border-radius: 12px 12px 0 0; background: #EEEEEE; }

.dash-block-bg-body { background: #EEEEEE; }

.dash-table .b-form-page__inner { background: #FFFFFF; }

/*select list*/
#overlay { position: absolute; z-index: 12; width: 100%; height: 100%; }

#overlay.hide { display: none; }

input[type='button']:focus { border-radius: 4px; outline: 0 none; }

/*Modal window*/
.active-modal #modal, .active-modal-alert #modal_alert { z-index: 106; display: block; }

.active-modal #modal_overlay, .active_mistake_form #modal_overlay, .active-modal-alert #modal_overlay { position: absolute; z-index: 105; display: block; width: 100%; height: 100%; opacity: .5; background: #000000; filter: progid:DXImageTransform.Microsoft.Alpha(opacity=50); }

.modal, .b-modal-mistake { position: fixed; top: 50%; left: 50%; display: none; width: 400px; margin-top: -50px; margin-left: -200px; padding: 10px; border-radius: 20px; background: #FFFFFF; }

#modal_alert.modal { text-align: center; }

.modal_content { padding: 22px; }

#modal_content, #modal_alert_inner, .b-modal-mistake__inner { border: 1px solid #CFD9E2; border-radius: 15px; background: #E1E7ED; }

#modal_content .l-buttons, #modal_alert .l-buttons, .b-modal-mistake .l-buttons { height: inherit; text-align: center; }

#modal_message, .modal_message { font-size: 12px; margin: 20px; max-height: 120px; overflow-y: auto; text-overflow: ellipsis; overflow-x: hidden; }

.modal-with-warning #modal_message, .modal-with-warning .modal_message { color: red; font-weight: bold; padding-left: 80px; }

.b-modal__inner { position: relative; }

#modal_overlay { display: none; }

/*remove extra padding ff*/
input[type='submit']::-moz-focus-inner { padding: 0; border: none; }

/*Login*/
.js-required { font-size: 20px; font-weight: bold; text-align: center; color: red; }

#login-wrapper { position: absolute; top: 50%; left: 50%; width: 260px; margin-top: -164px; margin-left: -135px; }

#login-form { width: 260px; height: 260px; border-radius: 135px; background: #78A5DF; }

#login-logo { text-align: center; margin-top: 37px; }

#login-form-form table { margin: 0 auto; }

#login-form-form table td { padding-bottom: 12px; }

#login-form-form table td.left { text-align: right; }

#login-form-form label { padding-right: 10px; color: #DBE7F7; }

#login-wrapper #links { display: table; margin: 0 auto; margin-top: 75px; }

#login-wrapper #copyright { margin: 0; padding: 0; }

#login-wrapper #links li { line-height: 23px; }

#login-wrapper #links li.help { background: url("../../common/img/sb-help.gif") no-repeat left; }

#login-form-form .loading { padding-top: 119px; text-align: center; color: white; }

#login-error { position: absolute; top: 86px; left: 275px; min-width: 200px; padding: 10px; border-radius: 10px; background: #FF8356; }

#login-error::before { position: absolute; top: 50%; left: -13px; width: 0; height: 0; margin-top: -8px; content: ''; border-width: 8px 16px 8px 0; border-style: outset solid; border-color: transparent #FF8356 transparent transparent; }

.b-body__login { min-height: 350px; position: relative; }

.mgrview { position: relative; width: 220px; height: 168px; }

.mgrview img { position: absolute; z-index: 1; }

.color { width: 100%; height: 25px; }

.color1 { background: #587B9D; }

.color2 { background: #78A5DF; }

.color3 { background: #E1E7ED; }

.color4 { background: #879CB5; }

.color5 { background: #537393; }

.color6 { background: #6086AB; }

.colo7 { background: #DAE2EC; }

#license-wrapper .login-label { margin-bottom: 2px; }

#license-wrapper { font-family: Tahoma, Verdana, Arial, Helvetica, sans-serif; font-size: 11px; font-weight: normal; }

.dash-table table.list_table { table-layout: inherit; }

.dash-table { width: 100%; padding-top: 26px; table-layout: fixed; }

.dash-table .dashboard-nodata { padding: 5px; }

.dashblock-reload { display: inline-block; zoom: 1; width: 15px; height: 9px; margin-left: 8px; vertical-align: middle; background: url(../img/reload.png) no-repeat center; *display: inline; }

.l-form-page__title-wr.loading .dashblock-reload::-moz-focus-inner, .l-form-page__title-wr.loading .dashblock-reload:focus, .dashblock-reload:focus { border: 0; outline: none; }

.l-form-page__title-wr.loading .dashblock-reload { -webkit-animation-name: rotate; -moz-animation-name: rotate; animation-name: rotate; -webkit-animation-duration: 1s; -moz-animation-duration: 1s; animation-duration: 1s; -webkit-animation-timing-function: linear; -moz-animation-timing-function: linear; animation-timing-function: linear; -webkit-animation-iteration-count: infinite; -moz-animation-iteration-count: infinite; animation-iteration-count: infinite; }

.dash-table div.b-form-page { position: relative; margin-bottom: 35px; }

.dash-table div.b-form-page.movingBlock { z-index: 100; }

.dash-table .toolbar { padding-top: 21px; }

#dash-top, #dash-left, #dash-right { margin-right: 22px; }

.left-row, .right-row { width: 50%; }

.dash-table table, .band-table table { width: 100%; }

.dash-table td { vertical-align: top; }

.dash-table .toolbar-button { width: 80px; margin-right: 25px; vertical-align: top; }

.dash-table .band-title.big-title { font-weight: bold; color: #6086AB; background: #EDF2F7; }

/*Progressbar*/
.depended-hidden { display: none; }

*[readonly], .b-radio.readonly, .row-shadow input, .depended-shadow-s input, .row-shadow .b-radio, .depended-shadow-s .b-radio { color: #444; }

.row-shadow input, .depended-shadow-s input { background: #FBFBFB; }

.depended-hidden-s { display: none; }

.warning td:first-child .data-wrapper { padding-left: 0; }

.warning-img { float: left; width: 19px; height: 22px; margin-right: 3px; margin-left: 14px; background: url(../../common/img/p-error.png) no-repeat 0 2px; }

.b-band__empty-overlay { position: absolute; width: 100%; height: 100%; opacity: 0.5; background: #FFFFFF; z-index: 98; }

.l-form__inner .b-band__empty-overlay { z-index: 12; }

.b-band__empty-msg { position: absolute; text-align: center; margin-top: 100px; font-size: 18px; width: 100%; z-index: 99; background: #FFFFFF; opacity: 0.7; }

.bands { position: relative; margin: 0 auto; }

.band-content table.sort_table { width: 669px; }

.fullwidth-band.band-content table.sort_table { width: 100%; }

.band-content.nodiagram .band-graphic, .band-content.nodiagram .band_show { display: none; }

.band-content { width: 669px; margin: 0 auto; margin-bottom: 20px; border-bottom: 1px solid #FFFFFF; }

.band-content.b-title { font-size: 14px; margin-bottom: 0; }

.band-table { position: relative; overflow: hidden; }

.band-table .list_table th { border-right: 1px solid #BFBFBF; }

.band-table .list_table th:last-child { border-right: 1px none; }

.band-table .list_table th .data-wrapper { line-height: 15px; padding-right: 0; padding-left: 0; white-space: normal; }

.band-table .list_table th .ovf { line-height: 15px; padding: 5px 14px; white-space: normal; }

.band-table .list_table .rowHandler { display: none; }

.band-content.fullwidth-band { width: 100%; }

.fullwidth-band .band-graphic { width: 100%; }

.band-graphic .notenoughdata { width: 100%; margin-bottom: 1px; text-align: center; border: 1px solid #CCCCCC; background: #EEEEEE; }

.band-graphic .notenoughdata td { height: 97px; vertical-align: middle; }

.band-content span[data-child-id] { cursor: pointer; text-decoration: underline; }

#hidden-cbutton { position: absolute; right: -20px; width: 20px; height: 100%; }

.active-menu #hidden-cbutton { display: none; }

.band-graphic { position: relative; width: 669px; min-height: 204px; }

.anychart { position: relative; }

.band-title.small-title { font-size: 18px; }

.band-title.big-title { font-weight: bold; padding: 5px 10px; color: #6086AB; background: #EDF2F7; }

.band_show { font-size: 11px; width: 100%; margin: 0 auto; margin-bottom: 5px; cursor: pointer; text-align: center; color: #537393; }

.band_show .show_msg { display: none; }

.band_show .hide_msg { display: inline-block; zoom: 1; *display: inline; }

.band_show .data-table-hidden .hide_msg { display: none; text-align: center; }

.band_show .data-table-hidden .show_msg { display: inline-block; zoom: 1; text-align: center; *display: inline; }

.band_show_button.data-table-hidden .triangle-band { font-size: 0; line-height: 0; top: 1px; display: inline-block; zoom: 1; width: 0; height: 0; margin-left: 7px; border-top: 4px solid #78A5DF; border-bottom: 4px solid #78A5DF; border-left: 4px solid #FFFFFF; *display: inline; }

.band_show_button .triangle-band { font-size: 0; line-height: 0; position: relative; top: -1px; display: inline-block; zoom: 1; width: 0; height: 0; margin-left: 7px; border-top: 4px solid #FFFFFF; border-right: 4px solid #78A5DF; border-left: 4px solid #78A5DF; *display: inline; }

.band_show_button { position: relative; display: inline-block; zoom: 1; padding: 3px 10px; text-align: center; color: #FFFFFF; border-radius: 9px; background: #78A5DF; *display: inline; }

.band-table .list_table tfoot td { font-weight: bold; line-height: 22px; padding: 0 14px; background: #C3D7EB; }

.band-table .nestedreport.data-wrapper { cursor: pointer; color: #537393; }

.content, .topWrapper { padding-right: 18px; }

.form-content .rightBar { padding-right: 0; }

#fake-wrapper { width: 10000px; height: 10000px; }

.userexperience { display: inline-block; zoom: 1; margin-bottom: 8px; margin-left: 245px; padding: 5px 10px; cursor: pointer; color: #5C81A5; border-radius: 10px; background: #E1E7ED; *display: inline; }

.a-lot-of-pages.userexperience { position: absolute; }

.dash-table .band-content { width: inherit; position: relative; }

body.ext-form div { font-size: 11px; }

.ext-form-table, #ext-form-main-wrapper { width: 100%; height: 100%; }

table.ext-form-table { text-align: center; }

#left #mainmenu .group li li.m-item a.wohan.newtab, #left #mainmenu .group li li.m-item:hover a.wohan.newtab { display: none; }

#edit-filed-in-list { position: absolute; z-index: 1; display: none; width: 22px; height: 22px; cursor: pointer; background-image: url(../img/edit.png); background-repeat: no-repeat; background-position: 5px 6px; }

#context-menu-icon { position: absolute; z-index: 1; display: none; width: 22px; height: 22px; cursor: pointer; background: url(../img/menu_down_arrow.png) no-repeat center; }

/* EULA */
body.eula-body { overflow: auto; }

div.eula-content { font-family: Tahoma, Verdana, Arial, Helvetica, sans-serif; width: 80%; margin: 20px auto; text-align: left; }

h1.eula { font-size: 16px; margin: 20px 0; text-align: center; }

h2.eula { font-size: 12px; margin: 10px 0; }

p.eula { font-size: 12px; text-align: justify; }

li.eula { font-size: 12px; padding-bottom: 6px; text-align: justify; }

.eula-content .button { margin-top: 10px; }

.eula-form-wrapper { text-align: center; font-size: 12px; }

.eula-form { margin: 20px 0; display: inline-block; }

/* EULA */
.report_info { padding: 20px 0; }

/* edit in list*/
.edit-field-form form { position: relative; display: inline-block; zoom: 1; *display: inline; }

.edit-field-form { position: absolute; z-index: 11; padding: 5px; white-space: nowrap; border: 1px solid #CCCCCC; background: #FFFFFF; }

.edit-field-form-loader { text-align: center; }

.edit-field-form:before { top: -10px; right: 5px; border-width: 0 0 9px 6px; border-style: solid; border-color: transparent transparent #C4B154 transparent; }

.edit-field-form:after { top: -9px; right: 6px; border-width: 0 0 9px 4px; border-style: solid; border-color: transparent transparent #F8F89F transparent; }

/* tree */
.tree-wrapper { overflow: auto; width: 100%; min-height: 100px; padding: 10px; border: 1px solid #CCCCCC; border-radius: 7px 0 7px 0; }

.l-form__table-formwidth .tree-wrapper { margin-left: -15px; }

.tree-wrapper ul li { padding-left: 20px; white-space: nowrap; background: url("../img/vline.gif") repeat-y 21px; }

.tree-wrapper ul li .tree-label { line-height: 16px; margin-left: 5px; padding-right: 3px; padding-left: 3px; }

.tree-wrapper .tree-handler { display: inline-block; zoom: 1; *display: inline; }

.tree-wrapper .bline { display: inline-block; vertical-align: top; }

.tree-wrapper .bline.icon img { width: 16px; height: 16px; }

.tree-wrapper .tree-label:hover { cursor: default; color: #FFFFFF; background: #6A93B7; }

.tree-wrapper .tree-handler.selected:hover .tree-label, .tree-wrapper .tree-handler.selected .tree-label { color: #FFFFFF; background: #6A93B7; }

.tree-hitarea { background: url("../img/tree.png") no-repeat 1px -1px; }

.tree-inner > ul > li { padding-left: 0; }

.tree-wrapper li.last { background: none; }

.tree-wrapper li.last > .tree-hitarea { background: url("../img/tree-last.png") no-repeat 4px 0; }

.tree-wrapper li.last.opened { background: url("../img/tree-last.png") no-repeat 10px -1px; }

.tree-wrapper .t-opened > .tree-hitarea, .tree-wrapper .t-opened.last > .tree-hitarea { background: url("../img/tree.png") no-repeat -30px -18px; }

.tree-wrapper li.collapsed > .tree-hitarea { background: url("../img/tree.png") no-repeat 1px -18px; }

.tree-wrapper li.last.collapsed > .tree-hitarea { background: url("../img/tree.png") no-repeat -16px -18px; }

.tree-wrapper .tree-hitarea { width: 15px; height: 15px; }

/*IDE*/
.toolbar-elem, .toolbar-elem .t-checkbox { display: inline-block; zoom: 1; margin-right: 5px; margin-bottom: 5px; vertical-align: top; *display: inline; }

.toolbar-elem label { line-height: 18px; }

.toolbar-editor { padding: 2px; padding-left: 0; white-space: normal; }

.ace_editor.fullscreen { position: fixed !important; z-index: 998; top: 0; right: 0; bottom: 0; left: 0; width: auto !important; height: auto !important; margin: 0; border: 0; }

.ace_editor { white-space: normal; border: 1px solid #CCCCCC; border-radius: 7px 0 7px 0; }

.exit-fullscreen.fullscreen { position: fixed; z-index: 999; top: 55px; right: 20px; display: block; }

.exit-fullscreen { display: none; }

.edit-field-form.active, #left #mainmenu .group li li.loading { -webkit-transition: width .4s ease-in-out; -moz-transition: width .4s ease-in-out; -ms-transition: width .4s ease-in-out; -o-transition: width .4s ease-in-out; transition: width .4s ease-in-out; -webkit-animation: animate-stripes 3s linear infinite; -moz-animation: animate-stripes 3s linear infinite; animation: animate-stripes 3s linear infinite; background-color: #2F8DD5; background-image: -webkit-gradient(linear, left top, right bottom, color-stop(0.25, rgba(255, 255, 255, 0.15)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.15)), color-stop(0.75, rgba(255, 255, 255, 0.15)), color-stop(0.75, transparent), to(transparent)); background-image: -webkit-linear-gradient(135deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent); background-image: -moz-linear-gradient(135deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent); background-image: -ms-linear-gradient(135deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent); background-image: -o-linear-gradient(135deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent); background-image: linear-gradient(135deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent); -webkit-background-size: 30px 30px; -moz-background-size: 30px 30px; background-size: 30px 30px; -webkit-box-shadow: 0 1px 0 #FFFFFF inset; -moz-box-shadow: 0 1px 0 #FFFFFF inset; box-shadow: 0 1px 0 #FFFFFF inset; }

@-webkit-keyframes animate-stripes { 0% { background-position: 0 0; }
  100% { background-position: 60px 0; } }

@-moz-keyframes animate-stripes { 0% { background-position: 0 0; }
  100% { background-position: 60px 0; } }

@keyframes animate-stripes { 0% { background-position: 0 0; }
  100% { background-position: 60px 0; } }

#edit-filed-in-list.active { -webkit-animation-name: rotate; -moz-animation-name: rotate; animation-name: rotate; -webkit-animation-duration: 1s; -moz-animation-duration: 1s; animation-duration: 1s; -webkit-animation-timing-function: linear; -moz-animation-timing-function: linear; animation-timing-function: linear; -webkit-animation-iteration-count: infinite; -moz-animation-iteration-count: infinite; animation-iteration-count: infinite; }

@-webkit-keyframes rotate { from { -webkit-transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); } }

@-moz-keyframes rotate { from { -moz-transform: rotate(0deg); }
  to { -moz-transform: rotate(360deg); } }

@keyframes rotate { from { transform: rotate(0deg); }
  to { transform: rotate(360deg); } }

.mgr-version { padding-left: 0 !important; color: red; }

/* Overrides extra padding in Firefox */
input::-moz-focus-inner { padding: 0 !important; border: 0 none !important; }

/*dcmap*/
.svg-wrapper { position: relative; }

.svg-wrapper svg { border: 1px solid #000000; }

.svg-wrapper svg .default-border { stroke-width: 1px; stroke: #000000; fill: none; }

.svg-wrapper svg .selected { opacity: .5; }

/*inspector form*/
#inspector_form { position: absolute; z-index: 20; top: 0; right: 0; display: none; width: 550px; padding: 10px; padding-top: 0; border: 1px solid #CFD9E2; border-radius: 20px; background: #E1E7ED; box-shadow: 5px 5px 14px 0px rgba(0, 0, 0, 0.22); }

.inspector-button { visibility: hidden; }

.inspector-content .rightBar { display: none; }

#inspector_form #inspector-content { background-color: #FFFFFF; border: 1px solid #CFD9E2; border-radius: 15px; }

#inspector_form.collapsed { top: inherit !important; right: 0 !important; bottom: 0; padding: 0 5px; }

.field-settings { display: inline-block; zoom: 1; margin-left: 5px; padding-top: 2px; vertical-align: top; }

#inspector_form.collapsed #inspector-content, #inspector_form .devel-link-settings, #inspector_form .devel-link-add, #inspector_form .devel-link-delete, #inspector_form .form-settings { display: none; }

.devel-link-settings { display: inline-block; width: 16px; height: 16px; background: url(../img/gear.png) no-repeat left top; }

.devel-link-settings--col { margin-left: 5px; vertical-align: middle; }

.devel-link-delete--col { vertical-align: middle; }

#left #mainmenu .group li li a.devel-link-settings--menu { position: absolute; left: 0; }

.devel-link-add { display: inline-block; width: 16px; height: 16px; background: url(../img/plus.png) no-repeat left top; }

.devel-link-delete { display: inline-block; width: 16px; height: 16px; background: url(../img/minus.png) no-repeat left top; }

.devel-link-add--col { position: absolute; top: 5px; left: -15px; }

.toolbutton-settings { position: absolute; top: 0; right: 0; }

.toolbutton-add { position: absolute; left: -15px; }

.menu_settings { position: fixed; z-index: 99; left: 164px; margin-top: -18px; }

.l-label__wrapper .devel-link-settings--field, .l-label__wrapper .devel-link-delete--field, .dash-table .devel-link-delete--col, .dash-table .devel-link-settings--col { display: none; }

.l-form__col_type_fullwidth .l-label__wrapper .devel-link-settings--field, .l-form__col_type_fullwidth .l-label__wrapper .devel-link-delete--field, .form-settings, .col-settings, .list-settings { display: inline-block; }

.l-devel.s16x16 { background: url("../../common/img/l-devel.png") no-repeat 0 0; }

.b-map-toolbar { padding: 5px; }

.b-map-toolbar__button { display: inline-block; margin-left: 1px; padding: 2px 5px; text-decoration: none; border: 1px solid #CCCCCC; }

.b-map-toolbar__button_save_yes { margin-right: 10px; }

.b-map-toolbar__button_zoomin { margin-left: 10px; }

.b-map-toolbar__button_active { font-weight: bold; }

/*google chart tooltip fixed */
.charts-tooltip { z-index: 999; }

.b-overlay { position: fixed; z-index: 9998; top: 0; display: none; width: 100%; height: 100%; opacity: .5; background: #E1E7ED; }

/*rack*/
.b-rack { width: 350px; border: 2px solid #000000; }

.b-rack-bottom { width: 350px; height: 25px; border-top: 2px solid #000000; }

.b-rack-btn { display: inline-block; margin-left: 1px; padding: 2px 5px; text-decoration: none; border: 1px solid #CCCCCC; }

.b-rack-btn_save { margin-right: 10px; }

.b-rack-btn_active { font-weight: bold; }

.b-rack__name { padding: 2px 5px; color: #FFFFFF; border-bottom: 1px solid #000000; background: #CCCCCC; }

.b-rack__numeric-list { line-height: 18px; width: 19px; text-align: center; vertical-align: middle; color: #FFFFFF; background: #858585; }

.b-rack__numeric-item { height: 18px; border-top: 1px solid #FFFFFF; }

.b-rack-unplaced { position: absolute; top: 0; left: 720px; min-width: 100px; max-width: 350px; }

.b-nodes { position: relative; }

.b-nodes_active-view .b-node__elem, .b-nodes_active-view .b-node__multi_elem { background-color: inherit !important; }

.b-node__elem { position: absolute; width: 330px; margin-left: 20px; border: 1px solid #CCCCCC; background: #FFFFFF; }

.b-node__elem_withoutpos { left: 350px; }

.b-node__mover { float: right; width: 10px; background: #D0482C; }

.b-node__name { line-height: 15px; float: left; padding-right: 5px; padding-left: 5px; }

.b-node__elem:focus, .b-node__multi_elem:focus, .b-node__elem_selected { outline: 1px solid #2CA82D; }

.b-node__elem:focus { z-index: 1; }

.b-node__multi_elem:focus { z-index: 2; }

.b-node__elem.b-node__elem_state_error, .b-node__multi_elem.b-node__elem_state_error { opacity: .7; outline: 1px solid #FF1B1B; }

.b-node__props { float: left; }

.b-node__multi_elem { position: absolute; border: 1px solid #CCCCCC; }

.b-node-multi__mover { position: absolute; bottom: 0; height: 10px; background: #D0482C; }

.b-node__multi_elem_unplaced { position: relative; z-index: 10; display: inline-block; }

.b-prop { display: inline-block; width: 4px; height: 4px; margin-left: 4px; vertical-align: middle; }

.b-prop_link_on { background-color: orange; }

.b-prop_power_on { background-color: green; }

.b-prop_link_off { background-color: #989898; }

.b-prop_power_off { background-color: #989898; }

.b-prop-multinode { width: 4px; height: 4px; margin-top: 4px; }

.b-node__props_multinode { float: right; width: 4px; margin-right: 4px; }

.b-node__name_multinode { position: absolute; transform: rotate(-90deg); transform-origin: top left; filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3); }

.b-hint-line__label { font-weight: bold; margin-right: 4px; }

.b-node-inner { float: left; width: 320px; }

.b-node__view { position: absolute; z-index: -1; display: none; width: 320px; background: #FFFFFF; }

.b-rack-legend { position: absolute; top: 18px; left: 360px; display: none; padding: 5px 25px; border: 1px solid #CCCCCC; }

.b-rack-legend__title { padding: 0 0 10px 0; }

.b-rack-legend__color { display: inline-block; width: 11px; height: 11px; }

.b-rack-legend__value { line-height: 11px; display: inline-block; padding-left: 5px; vertical-align: top; }

.b-rack-legend_dcmap { top: 0; right: 16px; left: inherit; opacity: .9; background: #FFFFFF; }

.b-map-status { display: none; color: red; }

.b-map-status_active { font-size: 11px; display: inline-block; padding-left: 10px; }

.b-notify-bar { position: absolute; top: 0; left: 205px; border-radius: 0 0 12px 12px; background: #E1E7ED; }

.b-notify-bar__item { display: inline-block; vertical-align: top; }

/** ticket **/
.b-ticket { font-family: Arial; font-size: 11px; background: #FFFFFF; }

.b-ticket__table { width: 100%; vertical-align: top; }

.b-ticket__item { position: relative; margin-bottom: 5px; padding: 7px; color: #000000; border: 1px solid #CCCCCC; border-radius: 4px; }

.b-ticket__title { margin-bottom: 10px; }

.b-ticket__table-row_is_collapsed .b-ticket__title { margin-bottom: 0; }

.b-ticket__item-inner_incoming .b-ticket__title { color: #00A957; }

.b-ticket__item-inner_outcoming .b-ticket__title, .b-ticket__item-inner_system .b-ticket__title, .b-ticket__item-inner_inner .b-ticket__title { color: #E86E3C; }

.b-ticket__item_incoming { margin-right: 22px; background: #FFFFFF; }

.b-ticket__table_my_outcoming .b-ticket__item_incoming { margin-left: 35px; }

.b-ticket__item-inner { position: relative; z-index: 2; min-height: 11px; }

.b-ticket__item-inner_has_rate { padding-bottom: 16px; }

.b-ticket__item_outcoming { margin-right: 22px; margin-left: 35px; background: #FBFBFB; }

.b-ticket__table_my_outcoming .b-ticket__item_outcoming { margin-left: 0; }

.b-ticket__item-inner_outcoming, .b-ticket__item-inner_incoming { min-height: 40px; }

.b-ticket__table-row_is_collapsed .b-ticket__item-inner_outcoming, .b-ticket__table-row_is_collapsed .b-ticket__item-inner_incoming { min-height: 10px; }

.b-ticket__item-inner_outcoming:before, .b-ticket__item-inner_incoming:before, .b-ticket__item-inner_outcoming:after, .b-ticket__item-inner_incoming:after { position: absolute; z-index: 2; top: 0; right: -17px; width: 0; height: 0; content: ''; border-width: 8px 10px 0 0; border-style: solid; border-color: #FFFFFF transparent transparent; }

.b-ticket__item-inner_outcoming:before { border-color: #FBFBFB transparent transparent transparent; }

.b-ticket__item-inner_outcoming:after, .b-ticket__item-inner_incoming:after { z-index: 1; top: -1px; right: -20px; border-width: 9px 12px 0 0; border-style: solid; border-color: #CCCCCC transparent transparent transparent; }

.b-ticket__item_inner { margin-right: 22px; margin-left: 35px; background: #FFFFDD; }

.b-ticket__item_system { margin-right: 22px; margin-left: 35px; background: #fff4f0; }

.b-ticket__item_ticketnote { margin-right: 22px; margin-left: 35px; background: #D2FDF4; }

.b-ticket__avatar { display: inline-block; border: 1px solid #CCCCCC; border-radius: 4px; background-color: #E1E7ED; height: 80px; }

.b-ticket__table-row_is_collapsed .b-ticket__avatar { height: 30px; }

.b-ticket__avatar-img { border-radius: 4px; }

.b-ticket__table-row_is_collapsed .b-ticket__avatar-img { height: 30px !important; width: 30px !important; }

.b-ticket__clear { clear: both; }

.b-ticket__file { margin-top: 5px; }

.b-ticket__table-row_is_collapsed .b-ticket__file { display: none; }

.b-ticket__table-col { vertical-align: top; }

.b-ticket__table-col_type_msg { width: 100%; }

/*long word break form*/
.b-ticket__body { word-break: break-all; -webkit-hyphens: auto; -moz-hyphens: auto; hyphens: auto; }

.b-ticket__table-row_is_collapsed .b-ticket__body { display: none; }

/*ticket msg info*/
.b-ticket__body_same_prop { word-break: break-word; }

.b-ticket__table-info { border-collapse: collapse; }

.b-ticket__item_info { margin-right: 20px; }

.b-ticket__col-info { padding: 1px 10px 1px 0; }

.b-ticket__col-info_type_header { color: #00A957; padding-bottom: 3px; padding-top: 15px; }

.b-ticket__row-info_type_header:first-child .b-ticket__col-info_type_header { padding-top: 0; }

.b-ticket__col-info_type_label { min-width: 230px; }

.b-ticket__rate { line-height: 16px; float: right; margin-bottom: -20px; text-align: right; }

.b-ticket__table-row_is_collapsed .b-ticket__rate { display: none; }

.b-ticket__rate-like, .b-ticket__rate-dislike, .b-ticket__rate_is_rated { display: inline-block; margin-left: 13px; vertical-align: top; }

.i-ticket__rate { cursor: pointer; }

.b-ticket__rate-like { color: #00A957; }

.b-ticket__rate_rated_dislike .b-ticket__rate-like { display: none; }

.b-ticket__rate-like.b-ticket__rate_is_rated { display: block; }

.b-ticket__rate-dislike { color: #E86E3C; }

.b-ticket__rate_rated_like .b-ticket__rate-dislike { display: none; }

.b-ticket__rate-dislike.b-ticket__rate_is_rated { display: block; }

.b-ticket__rate-msg_rated { display: none; }

.b-ticket__rate_rated_like .b-ticket__rate-msg_rated, .b-ticket__rate_rated_dislike .b-ticket__rate-msg_rated { display: inline; }

.b-ticket__rate-text { display: inline-block; vertical-align: top; }

.b-ticket__rate_rated_like .b-ticket__rate-msg, .b-ticket__rate_rated_like .b-ticket__rate-text, .b-ticket__rate_rated_dislike .b-ticket__rate-msg, .b-ticket__rate_rated_dislike .b-ticket__rate-text { display: none; }

.b-ticket__rate-img { width: 16px; height: 16px; margin-right: 5px; }

.b-ticket__rate_rated_like .b-ticket__rate-img, .b-ticket__rate_rated_dislike .b-ticket__rate-img { margin-right: 0; }

.b-ticket__btn-move-wrapper { right: 46px; position: fixed; top: 50%; }

.b-ticket__btn-move { display: block; font-size: 25px; color: #676A6C; cursor: pointer; }

.b-ticket__btn-move_up:before { content: '\2191'; }

.b-ticket__btn-move_down:before { content: '\2193'; }

.b-ticket__btn-collapse.s16x16 { display: none; }

.b-ticket__table-row_is_collapsible .b-ticket__btn-collapse { display: block; position: absolute; right: 0; top: -2px; cursor: pointer; }

.b-quote { display: inline-block; margin-bottom: 2px; }

.b-topmenu__label { display: inline-block; }

.b-myselect_topmenu { display: inline-block; }

.b-myselect_topmenu .b-myselect__option-value { line-height: 18px; }

.b-myselect_topmenu .b-myselect__img { margin-top: 1px; }

/*TinyMCE fullscreen fixed*/
div.mce-fullscreen { z-index: 999; }

/*styles for htmldata & textdata*/
.b-htmldata__inner ul li, .b-textdata__inner ul li { list-style: inherit; }

.b-htmldata__inner ul, .b-textdata__inner ul, .b-htmldata__inner ol, .b-textdata__inner ol { padding-left: 20px; list-style-position: inside; }

.b-htmldata__inner p, .b-textdata__inner p { margin-top: 1em; margin-bottom: 1em; }

.b-htmldata__inner table th, .b-htmldata__inner table td, .b-textdata__inner table th, .b-textdata__inner table td { font-weight: inherit; text-align: inherit; color: inherit; border: 1px solid #000000; }

.b-htmldata__inner table, .b-textdata__inner table { width: inherit; }

.b-textdata__inner table thead tr, .b-htmldata__inner table thead tr { border: inherit; background: inherit; }

.b-text-wrapper { word-wrap: break-word; }

.toolbtn-type-dummy { display: none; }

.toolbtn-type-dummy a { cursor: default; }

.toolgrp.collapsed .toolbtn-type-dummy { display: inline-block; }

.b-ticket-tools-link { display: inline-block; margin-right: 7px; }

.b-modal-mistake { display: none; }

.active_mistake_form .b-modal-mistake { display: block; z-index: 106; }

.b-modal-mistake__textarea, .b-modal-mistake__input { width: 90%; margin: 10px; height: 60px; }

.b-modal-mistake__introduce { padding: 10px; }

.bg__green { background: #00A676; }

.changelog { margin-left: 15px; }

.b-imglinks { text-align: center; }

.b-imglinks__link { padding: 3px; }

.rightBar .sb-help { background-position-y: -1px; }

input:invalid { -moz-box-shadow: none; box-shadow: none; }

.b-report-nodata { padding: 5px 10px; }

.l-form__row_has_addition .l-form__control_inline_yes { display: inline-block; }

.s-icon.transparent { background-position: -100px -100px; }

.ace_orion_highlight_red { background-color: #eca6a9; position: absolute; z-index: 6; }

/*Text styles*/
.b-text_style_bold { font-weight: bold; }

.b-elem_style_gracescale { /* Firefox 10+, Firefox on Android */ filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale"); /* IE6-9 */ filter: gray; /* Chrome 19+, Safari 6+, Safari 6+ iOS */ -webkit-filter: grayscale(100%); }

.color-red { color: red; }

.color-yellow { color: yellow; }

.color-green { color: #008000; }

.color-blue { color: blue; }

.color-orange { color: orange; }

/*Body*/
.b-body { font-family: Arial; font-size: 11px; font-weight: normal; }

.b-body_page_desktop { overflow: hidden; }

/*Logo*/
.b-logo { width: 187px; height: 70px; background-repeat: no-repeat; background-position: 50% 50%; }

/*Content block*/
.b-content { width: auto; }

.b-body_page_desktop .b-content { border-left: 2px solid #E1E7ED; }

.active-menu .b-content { border-left: 0 none; }

/*Horiz Scroll module*/
.b-hsc_overwidth_full .b-hsc__btn-left { display: block; }

.b-hsc_overwidth_full .b-hsc__btn-right { display: block; }

.b-hsc_overwidth_left .b-hsc__btn-left { display: block; }

.b-hsc_overwidth_right .b-hsc__btn-right { display: block; }

.b-hsc__btn-left { display: none; }

.b-hsc__btn-right { display: none; }

/*Toolbar move btn*/
.toolbar__inner { display: inline-block; }

.toolbar__btn-move-wr { width: auto; position: relative; }

.toolbar__btn-move { position: absolute; width: 10px; height: 50px; z-index: 9; top: 2px; background: #FFFFFF; display: none; }

.toolbar__btn-move_dir_left { left: 0; width: 0; height: 0; border-top: 20px outset transparent; border-right: 10px solid #879CB5; border-bottom: 20px outset transparent; }

.toolbar__btn-move_dir_right { right: 0; width: 0; height: 0; border-top: 20px outset transparent; border-bottom: 20px outset transparent; border-left: 10px solid #879CB5; }

/*Tab Box*/
.tab-content { position: relative; padding-left: 20px; }

.b-ispstore__wrapper .tab-content { padding: 0; }

.body-login-form .tab-content { position: inherit; padding: inherit; }

table.ext-form-table td .tab-content { text-align: left; }

#ext-form-main-wrapper .tab-content { height: inherit !important; }

.tab-content_st_active { margin-top: 0 !important; }

.ext-form-table__td { vertical-align: middle; }

/*Hint*/
.b-hint { position: absolute; z-index: 101; visibility: hidden; padding: 15px 0; }

.b-hint.active { visibility: visible; }

.b-hint .shadow_hint { display: none; }

.b-hint .more { display: none; }

.b-hint .red { color: red; }

.b-hint .green { color: #00A656; }

.b-hint .blue { color: #4179CD; }

.b-hint .yellow { color: #BFA136; }

.b-hint .cyan { color: #00B0B2; }

.b-hint__inner { position: relative; max-width: 300px; margin-right: 16px; padding: 8px; border: 1px solid #C4B154; border-radius: 7px; background: #F8F89F; word-break: break-word; }

.b-hint__inner .hint-shadow { display: block; font-weight: bold; }

.ie8 .b-hint__inner:before, .ie8 .b-hint__inner:after { content: none; border: 0 none; }

.b-hint__inner:before { position: absolute; width: 0; height: 0; content: ''; }

.b-hint__inner:after { position: absolute; width: 0; height: 0; content: ''; }

.b-hint__inner_left:before { bottom: -10px; left: 5px; border-width: 9px 6px 0 0; border-style: solid outset; border-color: #C4B154 transparent transparent transparent; }

.b-hint__inner_left:after { bottom: -9px; left: 6px; border-width: 9px 4px 0 0; border-style: solid outset; border-color: #F8F89F transparent transparent transparent; }

.b-hint__inner_right:before { right: 5px; bottom: -10px; left: inherit; border-width: 0 6px 9px 0; border-style: outset solid; border-color: transparent #C4B154 transparent transparent; }

.b-hint__inner_right:after { right: 6px; bottom: -9px; left: inherit; border-width: 0 4px 9px 0; border-style: outset solid; border-color: transparent #F8F89F transparent transparent; }

.b-hint__inner_top_right:before { top: -10px; right: 5px; bottom: inherit; left: inherit; border-width: 0 0 9px 6px; border-style: solid; border-color: transparent transparent #C4B154 transparent; }

.b-hint__inner_top_right:after { top: -9px; right: 6px; bottom: inherit; left: inherit; border-width: 0 0 9px 4px; border-style: solid; border-color: transparent transparent #F8F89F transparent; }

.b-hint__inner_top:before { top: -10px; bottom: inherit; left: 5px; border-width: 9px 0 0 6px; border-style: solid; border-color: transparent transparent transparent #C4B154; }

.b-hint__inner_top:after { top: -9px; bottom: inherit; left: 6px; border-width: 9px 0 0 4px; border-style: solid; border-color: transparent transparent transparent #F8F89F; }

.b-hint__inner_right_fixed:after { top: 6px; bottom: inherit; left: -9px; border-width: 0 9px 4px 0; border-style: solid; border-color: transparent #F8F89F transparent transparent; }

.b-hint__inner_right_fixed:before { top: 5px; bottom: inherit; left: -10px; border-width: 0 9px 6px 0; border-style: solid; border-color: transparent #C4B154 transparent transparent; }

/*Scrollbar*/
.scrollbar-wrapper { position: absolute; z-index: 100; top: 0; right: 0; width: 18px; height: 100%; background: #E1E7ED; }

.b-mselect__view-value .scrollbar-wrapper { z-index: 9; }

.scrollbar-wrapper-horiz { position: absolute; z-index: 100; right: 0; bottom: 0; width: 100%; height: 18px; background: #E1E7ED; }

.scrollbar-track { position: absolute; top: 18px; bottom: 18px; width: 18px; }

.scrollbar-track-horiz { position: absolute; right: 18px; left: 18px; height: 18px; }

.scrollbar-handler-horiz { position: absolute; width: 80px; height: 14px; margin: 2px 0; border-radius: 7px; background: #FFFFFF; }

.scrollbar-handler { position: absolute; width: 14px; height: 80px; margin: 0 2px; border-radius: 7px; background: #FFFFFF; }

.scrollbar-wrapper .top-button .triangl-top { font-size: 0; line-height: 0; width: 0; height: 0; margin: 7px auto; border-right: 4px solid #E1E7ED; border-bottom: 4px solid #879CB5; border-left: 4px solid #E1E7ED; }

.scrollbar-wrapper-horiz .top-button .triangl-top { font-size: 0; line-height: 0; width: 0; height: 0; margin: 7px auto; border-right: 4px solid #E1E7ED; border-bottom: 4px solid #879CB5; border-left: 4px solid #E1E7ED; }

.scrollbar-wrapper .top-button { position: absolute; z-index: 101; top: 0; width: 18px; height: 18px; cursor: pointer; }

.scrollbar-wrapper-horiz .left-button { position: absolute; z-index: 101; left: 0; width: 18px; height: 18px; cursor: pointer; }

.scrollbar-wrapper-horiz .right-button { position: absolute; z-index: 101; right: 0; width: 18px; height: 18px; cursor: pointer; }

.scrollbar-wrapper .bottom-button .triangl-bot, .filter .triangle-down { font-size: 0; line-height: 0; width: 0; height: 0; margin: 7px auto; border-top: 4px solid #879CB5; border-right: 4px solid #E1E7ED; border-left: 4px solid #E1E7ED; }

.scrollbar-wrapper-horiz .left-button .triangl-left { font-size: 0; line-height: 0; width: 0; height: 0; margin-top: 4px; margin-left: 7px; border-top: 4px solid #E1E7ED; border-right: 4px solid #879CB5; border-bottom: 5px solid #E1E7ED; }

.scrollbar-wrapper-horiz .right-button .triangl-right { font-size: 0; line-height: 0; width: 0; height: 0; margin: 4px auto; border-top: 4px solid #E1E7ED; border-bottom: 4px solid #E1E7ED; border-left: 4px solid #879CB5; }

.scrollbar-wrapper .bottom-button { position: absolute; z-index: 101; bottom: 0; width: 18px; height: 18px; cursor: pointer; }

.scrollbar-wrapper .scrollbar-lines { position: relative; height: 100%; }

.scrollbar-wrapper .scrollbar-line { position: absolute; top: 50%; width: 4px; height: 7px; margin-top: -4px; margin-left: 5px; background: url("../img/scroll-center-icon.png") no-repeat; }

/*Calendar*/
#calendar-cell { overflow: hidden; border: 1px solid #78A5DF; border-radius: 8px; background: #78A5DF; }

.cl { position: absolute; z-index: 22; top: 0; left: 0; visibility: hidden; border-collapse: collapse; }

#prevM .t-inner, #prevY .t-inner { width: 0; height: 0; margin-top: 1px; color: #78A5DF; border-width: 5px 5px 5px 0; border-style: outset solid; border-color: transparent #BBD2EF transparent transparent; }

#nextM .t-inner, #nextY .t-inner { line-height: 0; width: 0; height: 0; margin-top: 1px; color: #78A5DF; border-width: 5px 0 5px 5px; border-style: outset solid; border-color: transparent transparent transparent #BBD2EF; }

#prevM, #prevY, #nextM, #nextY { width: 10px; height: 10px; }

#calyear { width: 28px; text-align: center; }

#prevM { margin-left: 5px; }

#prevY { margin-left: 9px; }

#nextM { padding-left: 5px; }

#nextY { padding-left: 4px; }

#calmonth { width: 53px; text-align: center; }

div.cl-cell { color: #000000; }

.cl-days-wrapper { overflow: hidden; border-radius: 7px 7px 0; }

div.cl-ym-y { font-size: 10px; position: absolute; z-index: 23; top: 0; left: 0; visibility: hidden; width: 35px; border: 1px solid #78A5DF; border-radius: 3px; background: #FFFFFF; }

div.cl-ym-m { font-size: 10px; position: absolute; z-index: 23; top: 0; left: 0; visibility: hidden; width: 35px; width: 54px; border: 1px solid #78A5DF; border-radius: 3px; background: #FFFFFF; }

div.cl-ym-div { padding: 1px 0; cursor: default; text-align: center; background: #FFFFFF; }

div.cl-ym-diveven { padding: 1px 0; cursor: default; text-align: center; background: #EDF2F7; }

div.cl-ym-selected { color: #FFFFFF; background: #6A93B7; }

.cl-head { font-family: Verdana, Arial, Helvetica, sans-serif; font-size: 10px; font-weight: bold; width: 160px; padding: 3px 0; padding-right: 5px; text-align: left; color: #FFFFFF; background: #78A5DF; }

.cl-tool-td { font-family: Verdana, Arial, Helvetica, sans-serif; font-size: 10px; display: inline-block; zoom: 1; cursor: default; *display: inline; }

#today, .b-cal__today { float: right; width: 13px; height: 12px; margin-right: 2px; background: url("../img/cl_today.png") no-repeat 0 0; }

td.cl-tool-selected { color: #FFFFFF; background: #4D80B6; }

table.cl-days { width: 100%; border-collapse: collapse; }

tr.cl-dayst-tr td { font-family: Verdana, Arial, Helvetica, sans-serif; font-size: 10px; font-weight: bold; width: 14%; padding: 3px 0; cursor: default; text-align: center; text-transform: lowercase; color: #FFFFFF; background: #C9D2DC; }

td.cl-first.cl-days .inner-td, tr.cl-dayst-tr td.cl-first { margin-left: 5px; }

td.cl-last.cl-days .inner-td, tr.cl-dayst-tr td.cl-last { margin-right: 5px; }

tr.first-row td.cl-days .inner-td { margin-top: 6px; }

tr.last-row td.cl-days .inner-td { margin-bottom: 6px; }

td.cl-days { font-family: Verdana, Arial, Helvetica, sans-serif; font-size: 10px; cursor: default; text-align: center; background: #FFFFFF; }

td.cl-days .inner-td { line-height: 13px; width: 18px; height: 13px; margin: 0 auto; padding: 2px 0; }

td.cl-weekend { color: #E5693C; }

td.cl-days.cl-behind .inner-td, td.cl-days.cl-future .inner-td { color: #999999; background: #FFFFFF; }

td.cl-days.cl-behind.cl-even .inner-td { color: #999999; background: #EFEFEF; }

td.cl-weekend.cl-behind .inner-td, td.cl-weekend.cl-future .inner-td { color: #EFB9A8; }

td.cl-selected .inner-td { color: #FFFFFF; background: #6A93B7; }

.cl-days td:hover .inner-td, #calendar-y div.cl-ym-hover, #calendar-m div.cl-ym-hover { background: #AFCAEC; }

/*calendar month */
.b-cal__month-wrapper { width: 162px; padding: 7px 5px; background: #FFFFFF; }

.b-cal__month { display: inline-block; width: 50px; padding: 2px; text-align: center; }

.b-cal__month:hover { cursor: pointer; color: #000000; background: #AFCAEC; }

.b-cal__month_selected { color: #FFFFFF; background: #6A93B7; }

/*Fatal error*/
.b-fatal-error { position: absolute; top: 50%; left: 50%; width: 270px; height: 270px; margin: 0 auto; margin-top: -135px; margin-left: -135px; }

.b-fatal-error__round { width: 270px; height: 270px; border-radius: 270px; background: #FF8356; }

.b-err__unavailable .b-fatal-error__round { background: #FFD243; }

.b-fatal-error__triangle { font-size: 0; line-height: 0; width: 0; height: 0; margin-top: -48px; margin-left: 34px; border-width: 48px 48px 0 0; border-style: solid outset; border-color: #FF8356 transparent transparent transparent; }

.b-err__unavailable .b-fatal-error__triangle { border-color: #FFD243 transparent transparent transparent; }

.b-fatal-error__inner { padding-top: 23px; text-align: center; }

.b-fatal-error__sign { margin-bottom: 10px; }

.b-fatal-error__sign-top { width: 14px; height: 7px; margin: 0 auto; border-radius: 28px 28px 0 0; background: white; }

.b-fatal-error__sign-body { width: 0; height: 0; margin: 0 auto; content: ''; border-width: 54px 7px 0 7px; border-style: solid outset; border-color: #FFFFFF transparent transparent transparent; }

.b-fatal-error__sign-empty { z-index: 1; width: 10px; height: 11px; margin: 0 auto; margin-top: -7px; background: #FF8356; }

.b-err__unavailable .b-fatal-error__sign-empty { background: #FFD243; }

.b-fatal-error__sign-dot { width: 14px; height: 14px; margin: 0 auto; margin-top: 0; border-radius: 14px; background: white; }

.b-fatal-error__message { font-size: 12px; overflow: hidden; padding: 0 10px; }

/*Banner*/
.b-message { margin-top: 5px; padding-right: 16px; }

.b-message__triangle { font-size: 0; line-height: 0; width: 0; height: 0; margin-left: 10px; border-right: 10px solid #FFFFFF; border-left: 0 solid #FFFFFF; }

.b-message__triangle_status_advice { border-top: 10px solid #A9F1BA; }

.b-message__triangle_status_error { border-top: 10px solid #FF8356; }

.b-message__triangle_status_warning { border-top: 10px solid #FFD243; }

.b-message__inner { padding: 12px 20px; border-radius: 8px; }

.b-message__inner_status_advice { background: #A9F1BA; }

.b-message__inner_status_error { background: #FF8356; }

.b-message__inner_status_warning { background: #FFD243; }

.l-message__link { display: inline-block; zoom: 1; margin-right: 7px; *display: inline; }

/*Wizard's steps*/
.b-wizard { position: relative; overflow: hidden; padding: 13px; text-align: center; white-space: nowrap; border-radius: 10px; background: #E1E7ED; }

.b-wizard__inner { display: inline-block; zoom: 1; *display: inline; }

.b-wizard__arrow { display: inline-block; zoom: 1; width: 36px; height: 36px; padding: 0 25px; background: url("../img/step-arrow.png") no-repeat center; *display: inline; }

.b-wizard__triangle { position: absolute; top: 50%; margin-top: -20px; }

.l-wizard__triangle { position: absolute; top: 0; display: none; width: 20px; height: 100%; background: #E1E7ED; -webkit-user-select: none; -moz-user-select: none; user-select: none; -khtml-user-select: none; cursor: pointer; }

.b-wizard_overwidth_full .l-wizard__triangle { display: block; }

.b-wizard__triangle_dir_left { left: 5px; width: 0; height: 0; border-top: 20px outset transparent; border-right: 10px solid #FFFFFF; border-bottom: 20px outset transparent; }

.l-wizard__triangle_dir_left { left: 0; }

.b-wizard__triangle_dir_right { right: 5px; width: 0; height: 0; border-top: 20px outset transparent; border-bottom: 20px outset transparent; border-left: 10px solid #FFFFFF; }

.l-wizard__triangle_dir_right { right: 0; }

.b-wizard__step { display: inline-block; zoom: 1; vertical-align: top; *display: inline; }

.b-wizard__step-desc { font-size: 14px; }

.b-wizard__step-desc_st_active { font-size: 14px; }

.b-wizard__step-num { font-size: 14px; font-weight: bold; color: #868A8D; }

.b-wizard__step-num_st_active { font-size: 16px; color: #000000; }

.b-wizard__step-link { color: #868A8D; }

.b-wizard__bottom-padding { height: 10px; }

/*Buttons Container*/
.l-buttons { display: block; }

.l-buttons_for_form { height: 42px; padding-bottom: 10px; }

.l-buttons_for_editinlist { vertical-align: top; display: inline-block; height: inherit; padding-bottom: 0px; }

.l-buttons_for_fatalerror { position: absolute; width: 100%; text-align: center; bottom: 50px; }

.l-buttons_pos_fixed { position: fixed; z-index: 5; bottom: -15px; width: 100%; margin-left: -20px; padding-top: 10px; padding-left: 20px; background: #EDF2F7; }

.l-buttons_pos_bottom { background: #FFFFFF; }

/*Buttons*/
.b-button { font-size: 13px; line-height: 13px; position: relative; display: inline-block; zoom: 1; min-width: 46px; margin-right: 7px; padding: 5px 15px; cursor: default; text-align: center; border: 1px solid #B5BABF; border-radius: 7px; background: #D4D7DB; user-select: none; }

.b-button:hover { background: #C6C9CC; }

.b-button:active { background: #B7B9BD; border: 1px solid #999DA1; outline: 0 none; box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.3); border-color: #78A5DF !important; }

.b-button:focus { outline: 0 none; box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.3); border-color: #78A5DF !important; box-shadow: none; }

.b-form-list_view_table .b-button { font-size: 11px; padding: 2px 10px; }

.b-form-list__col-inner_type_button .b-button { margin-bottom: 5px; }

.l-buttons .b-button:first-child { margin-left: 3px; }

/*Buttons's color*/
.b-button_color_blue { border-color: #558BD0; background: #7EB1F3; }

.b-button_color_blue:hover { background: #75A7E9; }

.b-button_color_blue:active { background: #709DDF; }

.b-button_color_cyan { background: #1FB2B0; }

.b-button_color_cyan:hover { background: #1FA8A6; }

.b-button_color_cyan:active { background: #1E9E9C; }

.b-button_color_green { border-color: #1FB02B; background: #56DD61; }

.b-button_color_green:hover { background: #56D361; }

.b-button_color_green:active { background: #56C961; }

.b-button_color_red { border-color: #D0482C; background: #FF8356; }

.b-button_color_red:hover { background: #F57B55; }

.b-button_color_red:active { background: #EB7656; }

.b-button_color_yellow { background: #FFFF99; }

.b-button_color_yellow:hover { background: #F5F593; }

.b-button_color_yellow:active { background: #EBEB8D; }

.b-button_act_next { min-width: 55px; }

.b-button_act_next { min-width: 55px; }

.b-form-list__table .b-button_act_next { min-width: 46px; }

/*Disabled button*/
.b-button_st_disabled { color: #888888; }

.b-button_st_disabled:hover { border: 1px solid #B5BABF; }

/*Submit button*/
/*Cancel button*/
.b-button_type_cancel { font-weight: normal; margin-left: 7px; }

.b-button_type_login { border: 1px solid #5D83C7; background: #FFFFFF; }

.b-button_type_login:hover { background: #E2E4E5; }

/*Small buttons*/
.b-button_style_green { color: green; }

.b-button_style_red { color: red; }

.b-button_style_small { width: inherit; min-width: inherit; font-size: 10px; padding: 2px 5px; outline: 0 none; margin-right: 0; margin-left: 5px; }

/*For Fatal error*/
.b-button_for_fatalerror { margin: 0; }

/*Overlay*/
.b-tab-overlay { position: absolute; z-index: 10; display: none; width: 100%; height: 100%; margin-left: -20px; opacity: .5; background: #E1E7ED; filter: alpha(opacity=50); }

.b-ispstore__wrapper .b-tab-overlay { margin-left: 0; }

.loading .b-tab-overlay, .tab-content__progressbar_show .b-tab-overlay { display: block; opacity: .5; z-index: 23; }

.b-tab-overlay_show_edlist { opacity: .1; display: block; }

.b-tab-overlay_st_filter { display: block; opacity: 0; z-index: 20; }

/*Progressbar*/
.b-progressbar { position: absolute; z-index: 9999; top: 30%; display: none; width: 100%; margin-top: -50px; margin-left: -20px; }

.loading .b-progressbar { display: block; }

.b-progressbar__outer { width: 400px; margin: 0 auto; padding: 10px; border-radius: 20px; background: #FFFFFF; }

.b-progressbar__comment { margin-bottom: 20px; text-align: center; }

.b-progressbar__inner { padding: 22px; opacity: 1; border: 1px solid #CFD9E2; border-radius: 15px; background: #E1E7ED; filter: alpha(opacity=100); }

.b-progressbar_type_withbtn .b-progressbar__inner { padding-bottom: 5px; }

.b-progressbar__loader-wr { width: 100%; border: 1px solid #FFFFFF; border-radius: 15px; background: #FFFFFF; }

.b-progressbar__loader { height: 14px; padding-right: 7px; text-align: right; color: #FFFFFF; border-radius: 15px; background: #78A5DF; }

.b-progressbar__loader_type_static { background: #78A5DF; color: #FFFFFF; }

.b-progressbar__loader_type_animate { -webkit-transition: width .4s ease-in-out; -moz-transition: width .4s ease-in-out; -ms-transition: width .4s ease-in-out; -o-transition: width .4s ease-in-out; transition: width .4s ease-in-out; -webkit-animation: animate-stripes 3s linear infinite; -moz-animation: animate-stripes 3s linear infinite; animation: animate-stripes 3s linear infinite; background-color: #2F8DD5; background-image: -webkit-gradient(linear, left top, right bottom, color-stop(0.25, rgba(255, 255, 255, 0.15)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.15)), color-stop(0.75, rgba(255, 255, 255, 0.15)), color-stop(0.75, transparent), to(transparent)); background-image: -webkit-linear-gradient(135deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent); background-image: -moz-linear-gradient(135deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent); background-image: -ms-linear-gradient(135deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent); background-image: -o-linear-gradient(135deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent); background-image: linear-gradient(135deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent); -webkit-background-size: 30px 30px; -moz-background-size: 30px 30px; background-size: 30px 30px; -webkit-box-shadow: 0 1px 0 #FFFFFF inset; -moz-box-shadow: 0 1px 0 #FFFFFF inset; box-shadow: 0 1px 0 #FFFFFF inset; }

@-webkit-keyframes animate-stripes { 0% { background-position: 0 0; }
  100% { background-position: 60px 0; } }

@-moz-keyframes animate-stripes { 0% { background-position: 0 0; }
  100% { background-position: 60px 0; } }

@keyframes animate-stripes { 0% { background-position: 0 0; }
  100% { background-position: 60px 0; } }

.b-progressbar__btn-wr { text-align: center; margin-top: 5px; }

/*Form*/
.l-form__inner { position: relative; max-width: 99%; }

.l-form__inner_fly_buttons { padding-bottom: 42px; }

.l-form__wrapper { padding-right: 18px !important; }

.b-ispstore__wrapper .l-form__wrapper { padding: 0 !important; }

.b-body_page_extform .l-form__wrapper { width: 550px; margin: 0 auto; }

.l-form__table { width: 100%; min-width: 500px; }

.l-form_width_max .l-form__table { width: 100%; }

.b-form-page_withlist_yes .l-form__table { border-collapse: collapse; table-layout: fixed; }

.l-form__table .l-form__col { padding-top: 2px; padding-bottom: 3px; vertical-align: top; }

.b-form-page_withlist_yes .l-form__col { padding-top: 0; }

.l-form__row_type_link .l-form__col { padding-bottom: 0; vertical-align: bottom; }

.l-form__col_cont_hint { width: 17px; }

.edit-field-form .l-form__col_cont_hint { display: none; }

.l-form__col_cont_status { white-space: nowrap; }

.edit-field-form .l-form__col_cont_status { display: none; }

.l-form__row_type_ticket .l-form__col_cont_status { width: 16px; }

.l-form__col_cont_row-end { width: 100%; }

.row-error .l-form__col_cont_row-end, .l-form__row_type_listfilter .l-form__col_cont_row-end { display: none; }

.l-form__row_type_textdata.without-label .l-form__col_cont_control { min-width: 492px; }

.l-form__row.row-error .l-form__col_cont_control { width: 200%; }

.l-form__row.row-error.fullwidth-row .l-form__col_cont_control { width: 100%; }

.formwidth-row.l-form__row.row-error .l-form__col_cont_control { width: inherit; }

.l-form__row_type_tree.l-form__row .l-form__col_cont_control { width: 100%; }

.l-form__row_type_tree.l-form__row .l-form__col_cont_control.formwidth { width: inherit; }

.listfilter .l-form__col_cont_control { width: 100%; }

.l-form__row_type_listfilter .l-form__col_cont_control { width: 100%; }

.l-form__col-error { display: none; height: inherit; }

.row-error .l-form__col-error { display: table-row; }

.row-warning .l-form__col-error { display: table-row; }

.l-form__table-control { white-space: nowrap; border-spacing: 0; }

.listfilter .l-form__table-control { width: 100%; }

.tree .l-form__table-control { width: 100%; }

.formwidth-row.l-form__row_type_textarea .l-form__table-control { width: 100%; }

.l-form__table-control-wrapper { position: relative; }

.l-form__col-control { position: relative; min-width: 162px; padding: 2px; }

.l-form__col-control:empty { padding: 0; }

.l-form__row_type_text .l-form__col-control { width: 162px; }

.l-form__row_type_password .l-form__col-control { width: 162px; }

.l-form__row_type_select .l-form__col-control { width: 162px; }

.l-form__row_type_textarea .l-form__col-control { width: 162px; }

.l-form__row_type_checkbox.row-with-no-desc .l-form__col-control { width: 162px; }

.formwidth-row.l-form__row_type_textarea .l-form__col-control { width: inherit; }

.listfilter .l-form__col-control { width: inherit; }

.formwidth-row .l-form__table-formwidth .l-form__col-control { width: inherit; }

.formwidth-row.l-form__row_type_tree .l-form__table-formwidth .l-form__col-control { width: 100%; }

#ext-form-main-wrapper .l-form__row.l-form__row_type_radio .l-form__col-control { width: inherit; }

.l-form__row_type_textarea.fullwidth-row .l-form__col-control, .l-form__row_type_frame .l-form__col_type_fullwidth .l-form__col-control, .l-form__row_type_text .l-form__col_type_fullwidth .l-form__col-control { width: 100%; }

.l-form__row_type_link .l-form__col-control { padding-bottom: 0; vertical-align: bottom; }

.l-form__control-wr { position: relative; /*move to slider*/ }

.l-form__row_type_slider .l-form__table-formwidth .l-form__control-wr { margin-left: -15px; }

.l-form__row_type_slider .l-form__control-wr { min-width: 205px; padding: 0 10px 0 7px; border-radius: 10px; background: #E1E7ED; }

.l-form__row_hidden_yes { display: none; }

.depended-hidden-s { display: none; }

.b-form__row_visible_hidden { visibility: hidden; }

.b-form__row_fixed_yes { background-color: #FFF; z-index: 3; margin-left: -2px; margin-top: -2px; padding-top: 2px; }

.l-form__row_type_hidden { display: none; height: 0; }

.l-form__row_type_plainhint { font-style: italic; }

.l-form__table-formwidth { width: 100%; padding-left: 14px; }

.l-form__row_type_link.l-form__row .l-form__table-formwidth { padding-left: 0; }

.l-table-fullwidth { width: 100%; border-spacing: 0; }

/*Form pages head*/
.b-form-page { margin-right: 2px; margin-bottom: 20px; }

.b-form-page.l-form__row_hidden_yes { display: none; }

.b-form-page__title { font-size: 11px; font-weight: bold; position: relative; display: inline-block; zoom: 1; padding: 6px 15px; white-space: nowrap; text-decoration: none; color: #FFFFFF; border-radius: 12px 12px 0 0; background: #78A5DF; cursor: pointer; *display: inline; }

.b-form-page_st_collapsed .b-form-page__title { padding-right: 31px; border-radius: 12px; }

.b-form-page_view_dblock.b-form-page_st_collapsed .b-form-page__inner { position: absolute; display: block !important; visibility: hidden; width: 100%; }

.ie8 .b-form-page_view_dblock.b-form-page_st_collapsed .b-form-page__inner { display: none !important; }

.b-form-page_view_dblock .b-form-page__inner { display: block !important; }

.b-form-page__vline { height: 2px; background: #78A5DF; }

.b-form-page_st_collapsed .b-form-page__vline { display: none; }

.b-form-page_view_form .l-form-page__title-wr { margin-bottom: 12px; }

.b-form-page_withlist_yes .l-form-page__title-wr { margin-bottom: 0; }

.b-ispstore__wrapper .l-form-page__title-wr { display: none; }

.b-triangle { font-size: 0; line-height: 0; width: 0; height: 0; margin-left: 6px; border-top: 5px solid #FFFFFF; border-right: 5px solid #78A5DF; border-left: 5px solid #78A5DF; }

.b-form-page_st_collapsed .b-triangle { position: absolute; top: 8px; right: 12px; border-top: 5px solid #78A5DF; border-bottom: 5px solid #78A5DF; border-left: 5px solid #FFFFFF; }

/*Label*/
.l-label__inner { position: relative; height: 12px; }

.b-label__img { width: 16px; height: 16px; margin-right: 4px; margin-bottom: -1px; vertical-align: bottom; display: inline-block; }

.l-label__wrapper { display: inline-block; zoom: 1; width: 100%; min-width: 230px; max-width: 450px; height: 16px; padding-top: 3px; padding-right: 10px; text-align: right; vertical-align: top; *display: inline; }

.fullwidth-row .l-label__wrapper { width: inherit; min-width: inherit; max-width: inherit; }

.l-form__col_type_fullwidth .l-label__wrapper { text-align: left; }

.formwidth-row .l-label__wrapper { text-align: left; }

.b-form-list__col-inner .l-label__wrapper { display: none; }

.l-label__wrapper_for_checkbox { position: relative; width: inherit; min-width: 199px; margin-left: 5px; padding-right: 0; padding-top: 0; text-align: left; }

.filter-item .l-label__wrapper_for_checkbox { visibility: hidden; }

.l-checkbox-img .l-label__wrapper_for_checkbox { min-width: 178px; }

.l-form__row_has_addition .l-label__wrapper_for_checkbox { min-width: 85px; }

.b-form-list__filter .l-label__wrapper_for_checkbox { min-width: 25px; padding-right: 10px; }

.l-label__wrapper_for_radio { min-width: 201px; margin: 0; width: inherit; height: inherit; padding-top: 2px; padding-left: 5px; padding-right: 0; position: relative; }

.edit-field-form .l-label__wrapper_for_radio { min-width: 35px; }

.b-radio__inline .l-label__wrapper_for_radio { min-width: 1px; }

.b-label__visible { position: absolute; z-index: 2; bottom: -3px; left: 0; padding: 0; padding-right: 3px; padding-left: 7px; margin: 0; margin-left: -7px; cursor: default; white-space: nowrap; background: #FFFFFF; font-weight: normal; }

.fullwidth-row .b-label__visible { background: none; }

.b-label__visible_for_radio { top: 2px; left: 5px; margin-left: 0; padding-left: 0; }

.b-label__visible_for_checkbox { top: 0; bottom: 0; padding-left: 0; padding-right: 0; padding-top: 3px; margin-left: 0; cursor: default; vertical-align: top; }

.b-checkbox__wrapper_for_confirmbox .b-label__visible_for_checkbox { background: #E1E7ED; }

.b-label__visible_for_checkbox-in-list { position: relative; margin-left: 10px; background: inherit; display: inline-block; vertical-align: top; }

.b-label__invisible { visibility: hidden; text-align: left; white-space: nowrap; }

.b-label__line-dotted { position: absolute; z-index: 1; bottom: -1px; left: -7px; display: inline-block; width: 100%; border-bottom: 1px dotted #999999; }

.nohint .b-label__line-dotted, .fullwidth-row .b-label__line-dotted, .formwidth-row .b-label__line-dotted { display: none; }

.b-label__line-dotted_for_checkbox { bottom: 3px; left: 0; width: 99%; }

.b-checkbox__wrapper_for_confirmbox .b-label__line-dotted_for_checkbox { display: none; }

.b-form-list__filter .b-label__line-dotted_for_checkbox { display: none; }

.b-label__line-dotted_for_radio { bottom: 2px; left: 5px; display: none; width: 96%; }

.nohint .b-label__line-dotted_for_radio, .nohint .l-radio__item:first-child .b-label__line-dotted_for_radio { display: none; }

.l-radio__item:first-child .b-label__line-dotted_for_radio { display: block; }

.edit-field-form .l-radio__item:first-child .b-label__line-dotted_for_radio, .edit-field-form .b-label__line-dotted_for_radio { display: none; }

/*Required star*/
.b-required-star { position: absolute; z-index: 2; right: 4px; bottom: -13px; display: inline-block; zoom: 1; width: 13px; padding-left: 4px; background: #FFFFFF; color: red; *display: inline; font-size: 25px; vertical-align: middle; }

.formwidth-row .b-required-star, .fullwidth-row .b-required-star { position: relative; z-index: 0; right: inherit; bottom: inherit; margin-top: -2px; }

/*Field help in form row*/
.field-help { width: 17px; height: 17px; }

.b-form-row_with_quote .field-help { margin-top: 13px; }

.formwidth-row .field-help { margin-left: 1px; }

.field-help_available_yes { background: url(form_icons.png) no-repeat 2px 0; }

.b-ispstore__wrapper .field-help_available_yes { display: none; }

/*Field status in form row*/
.field-status { display: inline-block; width: 16px; height: 16px; margin-left: 7px; }

.row-ok .field-status { display: inline-block; zoom: 1; background: url(../img/form_icons.png) no-repeat -25px 0; *display: inline; }

.row-error .field-status { display: inline-block; zoom: 1; background: url(../img/form_icons.png) no-repeat -24px -30px; *display: inline; }

/*Field desc in form row*/
.field-desc { display: inline-block; zoom: 1; min-width: 1px; margin-left: 5px; padding-top: 2px; vertical-align: top; *display: inline; }

/*Validator error in form*/
.editlist-err-cont .b-error-box { width: 276px; }

.row-warning .b-error-box { position: absolute; z-index: 1; left: 303px; top: 4px; }

.row-warning .b-error-box:before { top: 5px; bottom: inherit; left: -9px; border-width: 0 10px 7px 0; border-style: solid; border-color: transparent #FFD243 transparent transparent; position: absolute; width: 0; height: 0; content: ""; }

.b-error-box__inner { padding: 10px; white-space: normal; border-radius: 10px; background: #FF8356; }

.edit-field-form .b-error-box__inner { white-space: normal; }

.row-warning .b-error-box__inner { background: #FFD243; min-width: 230px; }

.b-error-box__top-triangle { width: 0; height: 0; margin-left: 15px; border-right: 10px solid white; border-bottom: 10px solid #FF8356; border-left: 0 solid white; }

.row-warning .b-error-box__top-triangle { display: none; border-bottom: 10px solid #FFD243; }

/*Clean Table style*/
.l-table-clean { border-spacing: 0; }

/*Input*/
.b-input { font-family: Arial; font-size: 11px; padding: 0 7px; width: 206px; height: 16px; border: 1px solid #CCCCCC; border-radius: 4px; background: #FFFFFF; }

.b-ispstore__wrapper .b-input { line-height: 18px; }

.l-form__row_has_addition .b-input { width: 92px; }

.l-form__row_propwidth .b-input { width: 159px; }

.b-input:focus { outline: 0 none; box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.3); border-color: #78A5DF !important; }

.b-input:hover { outline: 0 none; box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.3); border-color: #BBB; }

.l-form__row_type_text .l-form__col_type_fullwidth .b-input { width: 100%; }

.b-input[readonly] { background: #FBFBFB; color: #444; }

.l-form__row.zoom .b-input { display: none; }

.l-form__col_type_fullwidth .l-form__input-wr { padding-right: 14px; }

.zoom .l-form__input-wr { padding-right: 0; }

.l-form__row_has_addition .l-form__input-wr { display: inline-block; vertical-align: top; }

.l-form__row_has_addition .l-form__input-wr:first-child { margin-right: 6px; }

.b-input_type_file { position: absolute; z-index: -1; visibility: hidden; }

.formwidth-row .b-input_type_text { width: 100%; min-width: 448px; margin-left: -15px; }

.b-input_type_search { width: 140px !important; margin-right: 21px; margin-top: 5px; margin-left: 13px; margin-bottom: 6px; vertical-align: top; }

/*Input for slider*/
.b-input_for_slider { width: 32px; height: 13px; }

/*Input for pager*/
.b-input_for_pager { width: 29px; }

/*Input for search box*/
.b-input_for_search { width: 100%; padding-left: 23px; background: url(../img/mag.png) no-repeat 5px center #FFFFFF; }

.b-input_for_search:focus { outline: 0 none; box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.3); border-color: #78A5DF !important; }

.b-input_for_search:hover { outline: 0 none; box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.3); border-color: #BBB; }

.l-form__row_has_addition .b-input_for_search { width: 78px; }

.l-form__row_propwidth .b-input_for_search { width: 189px; }

/*Input for login*/
.b-input_for_login { width: 116px; border: 1px solid #5D83C7; }

.b-input_for_login.required { border: 1px solid red; }

.b-input_has_prefixselect.b-input { border-radius: 0 4px 4px 0; width: 165px !important; }

.b-input__prefix { line-height: 16px; position: absolute; top: 0px; height: 16px; padding-left: 7px; color: #777777; border: 1px solid #CCCCCC; border-right: 0 none; border-radius: 4px 0 0 4px; background: #FFFFFF; }

.formwidth-row .b-input__prefix { left: -15px; }

input.b-input_type_text:focus + div { outline: 0 none; box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.3); border-color: #78A5DF !important; }

input.b-input_type_text:hover + div { outline: 0 none; box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.3); border-color: #BBB; }

/*Input help buttons*/
.b-input-btn { position: absolute; z-index: 1; top: 1px; right: 1px; width: 15px; height: 16px; cursor: pointer; }

.b-ispstore__wrapper .b-input-btn { top: 3px; }

.b-input-btn.b-input-btn_is_left { right: 21px; }

/*Input type=file fake box*/
.b-input-file-fake { font-family: Arial; font-size: 11px; line-height: 16px; position: relative; overflow: hidden; width: 213px; height: 16px; padding-left: 7px; cursor: default; border: 1px solid #CCCCCC; border-radius: 4px; background: #FFFFFF; }

.b-input-file-fake:hover { outline: 0 none; box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.3); border-color: #BBB; }

.b-input-file-fake:focus { outline: 0 none; box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.3); border-color: #78A5DF !important; }

.b-input-file-fake__btn { line-height: 14px; position: absolute; top: 0; right: 0; height: 14px; padding: 0 7px; border: 1px solid #999DA1; border-radius: 4px; background: #D4D7DB; }

.b-input-file-fake__input { line-height: 16px; height: 16px; }

/*Unlimit btn*/
.b-input-btn_type_unlimit { width: 17px; background: url("../img/unlim.png") no-repeat 0 0; }

/*Passwd gen btn*/
.b-input-btn_type_passwd { background: url("../img/passwd.png") no-repeat 0 0; }

/*Zoom btn*/
.b-input-btn_type_zoom { background-image: url("../img/nzoom.png"); background-position: -1px 0; background-repeat: no-repeat; }

.zoom .b-input-btn_type_zoom { background-position: -1px -15px; }

/*Calendar btn*/
.b-input-btn_type_calendar { background: url("../img/calendar.png") no-repeat 0 0; }

.filter-input .b-input-btn_type_calendar { right: 0; }

/*Change btn*/
.b-input-btn_type_change { background: url("../img/reload.png") no-repeat 0 0; }

/*Minus btn*/
.b-input-btn_type_minus { width: 16px; top: 1px; left: 205px; background: url(../img/minus.png) no-repeat 0 0; }

.vertical-scroll-child > .b-input-btn_type_minus { left: 187px; }

/*Plus btn*/
.b-input-btn_type_plus { width: 16px; left: 205px; background: url(../img/plus.png) no-repeat 0 0; }

.vertical-scroll-child > .b-input-btn_type_plus { left: 187px; }

.b-input-btn_type_showpwd { background-image: url("../img/passclose.png"); background-repeat: no-repeat; background-position: 0 0; right: 3px; }

.b-input-btn_type_showpwd.b-input-btn_pwd_liketext { background-position: 0 -26px; }

/*Checkpass notification*/
.checkpass-indicator-wrapper > div { display: none; }

.checkpass-indicator-wrapper > div.showed { display: block; height: 13px; margin-top: 2px; }

.strength-pass-indicator { display: inline-block; zoom: 1; width: 50%; *display: inline; }

.ci-square { display: none; height: 6px; border-radius: 3px; }

.short .ci-square.si-one { display: inline-block; zoom: 1; width: 40px; background: #E43F3E; *display: inline; }

.weak .ci-square.si-two { display: inline-block; zoom: 1; width: 80px; background: #E4AC3E; *display: inline; }

.good .ci-square.si-three { display: inline-block; zoom: 1; width: 120px; background: #9BCD36; *display: inline; }

.strong .ci-square.si-four { display: inline-block; zoom: 1; width: 160px; background: #2CA82D; *display: inline; }

.strength-pass-text { display: inline-block; zoom: 1; width: 50%; text-align: right; *display: inline; }

.st-text { display: none; padding-right: 3px; }

.nothing .st-zero, .short .st-one, .weak .st-two, .good .st-three, .strong .st-four { display: inline-block; zoom: 1; *display: inline; }

.b-form-confirm-message { display: none; text-align: right; }

.b-form-confirm-message_show_true { display: block; }

/*Textdata*/
.b-textdata { line-height: 21px; min-width: 221px; min-height: 18px; }

.b-textdata:empty { min-height: 0px; }

.without-label .b-textdata { width: inherit; }

.l-form__row_has_addition .b-textdata { display: inline; }

.l-form__row_has_addition .b-textdata__clear { display: none; }

.b-textdata__inner { white-space: normal; width: 100%; }

.l-form__row_has_addition .b-textdata__inner { display: inline; }

.b-textdata__img { float: left; padding: 0 5px 5px 0; }

.b-textdata_type_warning { color: #FF1B1B; }

/*Htmldata*/
.b-htmldata__img { float: left; padding: 0 5px 5px 0; }

.b-htmldata__inner { min-width: 220px; white-space: normal; }

.formwidth-row .b-htmldata__inner { min-width: 464px; margin-left: -15px; }

/*Slider*/
.b-slider { width: 100%; border-collapse: separate; border-spacing: 2px; }

.formwidth-row .b-slider { min-width: 447px; }

.l-slider__col_cont_input { width: 32px; }

.l-slider__col_cont_slider { padding-bottom: 3px !important; }

.b-slider__border { width: 1px; height: 3px; margin-top: -3px; background: #95A8BF; }

.b-slider__border_pos_left { float: left; }

.b-slider__border_pos_right { float: right; }

.b-slider__c-border { position: absolute; left: 50%; width: 1px; height: 2px; margin-left: .5px; }

.b-slider__c-border_pos_bottom { margin-top: -1px; background: #FFFFFF; }

.b-slider__c-border_pos_top { margin-top: -3px; background: #95A8BF; }

.b-slider__core { width: 3px; height: 3px; border: 1px solid #DBE1E9; border-radius: 3px; background: #FFFFFF; }

.b-slider__el { position: absolute; z-index: 2; width: 5px; height: 5px; margin-top: -6px; padding: 2px; border-radius: 9px; background: #8EA2BA; }

.l-slider__input-wr { float: right; }

.b-slider__line { width: 100%; height: 1px; margin-top: 3px; border-bottom: 1px solid #FFFFFF; background: #95A8BF; }

.l-slider__wr { position: relative; min-width: 150px; padding: 4px 0; }

/*Textarea*/
.b-textarea { font-family: Arial; font-size: 11px; border: 1px solid #CCCCCC; border-radius: 4px; background: #FFFFFF; overflow: auto; min-width: 206px; padding: 4px 7px; resize: none; }

.b-textarea:focus { outline: 0 none; box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.3); border-color: #78A5DF !important; }

.b-textarea:hover { outline: 0 none; box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.3); border-color: #BBB; }

.row-shadow .b-textarea, .depended-shadow-s .b-textarea { background: #FBFBFB; color: #444; }

.b-textarea[readonly] { background: #FBFBFB; color: #444; }

.l-form__row_type_textarea .l-form__col_type_fullwidth .b-textarea { display: block; width: 100%; }

.formwidth-row .b-textarea { width: 100%; min-width: 426px; margin-left: -16px; padding-right: 11px; }

.l-form__col_type_fullwidth .b-textarea__wrapper { padding-right: 17px; }

.b-textarea_for_zoom { width: 187px !important; min-width: 187px; display: none; }

.fullwidth-row .b-textarea_for_zoom { width: 100% !important; padding: 0; }

.l-form__row.zoom .b-textarea_for_zoom { display: block; margin-right: 19px; }

.formwidth-row .b-textarea_for_zoom { width: 100% !important; }

/*Checkbox*/
.b-checkbox { display: inline-block; zoom: 1; *display: inline; }

.l-form__row_has_addition .b-checkbox__wrapper { display: inline-block; }

.b-checkbox__wrapper_for_confirmbox { padding-left: 20px; padding-bottom: 10px; }

.b-form-list__filter .b-checkbox__wrapper { display: inline-block; flex: 0 0 170px; align-self: flex-start; white-space: nowrap; }

.b-checkbox__control { display: inline-block; zoom: 1; width: 18px; height: 17px; border-radius: 4px; background: url(checkboxes.png) no-repeat 0 -19px #FFFFFF; *display: inline; /*Checkbox checked*/ /*Checkbox readonly & in shadow*/ /*Checked readonly & in shadow*/ }

.b-checkbox__control:focus { outline: 0 none; box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.3); border-color: #78A5DF !important; }

.b-checkbox__control:hover { outline: 0 none; box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.3); border-color: #BBB; }

.b-checkbox__control.checked { background: url(checkboxes.png) no-repeat 0 -1px #FFFFFF; }

.row-shadow .b-checkbox__control, .b-checkbox__control.readonly { background-position: 0 -91px; }

.b-checkbox__control.checked.readonly, .row-shadow .b-checkbox__control.checked { background-position: 0 -73px; /*checkbox with mixed=yes*/ }

.b-checkbox_mixed_yes .b-checkbox__control { background-position: 0 -109px; }

.toolbar-elem .b-checkbox__control { vertical-align: bottom; }

.b-form-list_view_table .b-checkbox__control { vertical-align: middle; margin-top: 2px; }

.b-checkbox__img { margin-right: 5px; }

/*Radiobutton*/
.b-radio { display: inline-block; zoom: 1; *display: inline; }

.b-radio.readonly { color: grey; }

.b-radio__control { width: 16px; height: 16px; background: url(radios.png) no-repeat 0 -17px; }

.b-radio-img__col_radio .b-radio__control { margin-right: 15px; }

.b-radio__control.checked { background: url(radios.png) no-repeat 0 0; }

.b-radio__control.checked:hover { background-position: 0 -35px; }

.b-radio__control:hover { background-position: 0 -53px; }

.b-radio__control:focus { border-radius: 10px; outline: 0 none; box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.3); border-color: #78A5DF !important; }

.b-radio__control:hover { border-radius: 10px; outline: 0 none; box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.3); border-color: #BBB; }

.b-radio__control-wr { display: inline-block; zoom: 1; *display: inline; }

.b-radio__inline .l-radio__item { display: inline-block; margin-right: 20px; }

/*Radiobutton with image*/
.b-radio-img__col { vertical-align: middle; }

.b-radio-img__img { display: inline-block; background-repeat: no-repeat; background-position: 0 0; }

.b-radio-img__img-wrapper { margin-right: 15px; }

/*Form table list*/
.b-form-list .b-form-list__control-wrapper { display: inline-block; vertical-align: top; }

.b-form-list .b-form-list__hint { display: inline-block; }

.b-form-list .b-form-list__filter { padding: 10px 0; }

.b-form-list__filter-clear { display: block; vertical-align: top; padding-top: 3px; cursor: pointer; color: #537393; }

.b-form-list__filter_filter_yes .b-form-list__filter-clear { display: block; }

.b-form-list__col { vertical-align: middle; }

.b-form-list__row .b-form-list__col { border-bottom: 1px solid #B5BABF; }

.b-form-list_view_table .b-form-list__row .b-form-list__col { border-bottom: none; }

.b-form-list__row:last-child .b-form-list__col { border-bottom: 0 none; }

.b-form-list__col_align_center { text-align: center; }

.b-form-list__col_align_left { text-align: left; }

.b-form-list__col_align_right { text-align: right; }

.b-form-list__col_type_img { vertical-align: top; }

.b-form-list__col_type_data { min-width: 120px; }

.b-form-list__col_header_yes { background: #EDF2F7; color: #6086AB; font-weight: bold; text-align: left; border-right: 1px solid #BFBFBF; border-bottom: 0 none; }

.b-form-list__col_header_yes:last-child { border-right: 0 none; }

.b-form-list__col_header_yes:before { content: ""; width: 1px; background: #FFFFFF; min-height: 22px; display: block; position: absolute; }

.b-form-list__col_header_yes:first-child:before { display: none; }

.b-form-list__button-wrapper { text-align: center; }

.b-form-list__col-inner { padding: 7px 12px; }

.b-form-list_view_table .b-form-list__col-inner { min-height: 22px; line-height: 22px; padding: 0; padding-left: 14px; }

.b-form-list__col-inner_type_control, .b-form-list_view_table .b-form-list__col-inner_type_control { line-height: 16px; }

.b-form-list_view_table .b-form-list__col_header_yes .b-form-list__col-inner_type_control { line-height: 22px; }

.b-form-list__col-inner_type_control { white-space: nowrap; vertical-align: top; }

.b-form-list__row:hover { background: #EDF2F7; }

.b-form-list_view_table .b-form-list__row:hover { background: #D0E8F8; }

.b-form-list_view_table .b-form-list__row_t_even { background: #EDF2F7; }

.b-form-list__table { width: 100%; border-collapse: collapse; }

.b-form-list__table_filter_yes .row__nohas__tags { display: none; }

.b-form-list__img { border: 0 none; padding: 0; margin: 0; }

.b-form-list__filter { white-space: normal; display: flex; flex-wrap: wrap; flex-direction: row; justify-content: space-between; }

.b-form-list__filter-notfound { margin-top: 20px; display: none; }

/*Form blocks*/
.b-form-blocks__block { display: inline-block; position: relative; min-width: 250px; max-width: 250px; margin-right: 15px; margin-bottom: 15px; vertical-align: top; /*display: table-cell;*/ border: 1px solid #CCCCCC; border-radius: 15px; }

.b-form-blocks__block:hover { background: #EDF2F7; }

.b-form-block__block_set_height .b-form-blocks__col-inner_type_button { position: absolute; bottom: 5px; width: 100%; padding-left: 0; padding-right: 0; }

.b-form-blocks__col-inner_type_img { float: left; }

.b-form-blocks__label { font-size: 14px; color: #676666; margin-bottom: 20px; margin-top: 20px; }

/*Select autocomplete*/
.b-select-ac { position: relative; }

.b-select-ac__font-normal { font-weight: bold; }

.b-select-ac__input { width: 190px; padding-left: 23px; background-image: url("../img/sprite-icons.png"); background-repeat: no-repeat; background-position: 5px -214px; background-color: #FFFFFF; }

.b-select-ac__list-item { font-size: 11px; min-width: 205px; height: 16px; padding: 0 7px; list-style: none; cursor: default; white-space: nowrap; }

.b-select-ac__list-item:hover { background: #D0E8F8; }

.b-select-ac__list-wrapper { display: none; overflow: hidden; min-height: 16px; max-height: 224px; position: absolute; z-index: 3; min-width: 205px; border: 1px solid #CCCCCC; border-radius: 4px; background: #FFFFFF; }

.b-select-ac__list-scroll-wrapper { position: absolute; }

.b-select-ac__list-item_notfound_yes { font-weight: normal; }

.b-select-ac__list-item_selected_yes { background: #E1E7ED; }

.b-select-ac__list-item_selected_yes:hover { background: #E1E7ED; }

/*Multiselect*/
.b-mselect { text-align: left; }

.b-mselect.selected .b-input-btn_type_plus { display: none; }

.b-mselect .b-input-btn_type_minus { display: none; }

.b-mselect.selected .b-input-btn_type_minus { display: block; }

.b-mselect__item { cursor: default; }

.b-mselect__item.chosen { background-color: #E1E7ED; }

.b-mselect__ul-view .b-mselect__item { display: inline-block; overflow: hidden; zoom: 1; max-width: 220px; margin-top: 1px; margin-right: 1px; margin-bottom: 1px; padding: 1px 5px; line-height: 12px; cursor: default; text-overflow: ellipsis; background: #ECEFF3; border-radius: 4px; *display: inline; }

.b-mselect__ul-view .b-mselect__item:nth-child(2) { max-width: 186px; }

.b-mselect__item:hover { background: #D0E8F8; }

.b-mselect__list-value .b-mselect__item { font-size: 11px; min-width: 186px; height: 16px; line-height: 16px; padding: 0 7px; list-style: none; cursor: default; white-space: nowrap; }

.b-mselect__item.b-mselect__item_select-all { border-bottom: 1px solid #CCC; background-image: none; }

.b-mselect__item.b-mselect__item_select-all:hover { background: none; }

.b-mselect__ul-choose .b-mselect__item.b-mselect__item_select-all { background-image: none; }

.b-mselect__ul-choose .b-mselect__item { padding-left: 20px; background-image: url("checkboxes_15.png"); background-repeat: no-repeat; background-position: 2px -15px; }

.b-mselect__ul-choose .b-mselect__item.chosen { background-position: 2px 0; }

.b-mselect_mixed_yes .b-mselect__item_msg_unselected { display: none; }

.b-mselect__ul-view .b-mselect__item_msg_unselected { line-height: 12px; display: block; padding: 0 5px; text-align: left; border: 0 none; background: #FFFFFF; }

.selected .b-mselect__ul-view .b-mselect__item_msg_unselected { display: none; }

.b-mselect__ul-view .b-mselect__item_msg_unselected:hover { background: #FFFFFF; }

.b-mselect.readonly .b-mselect__ul-view .b-mselect__item_msg_unselected:hover { background: #F7F7F7; }

.b-mselect__item_st_selected { background: #D0E8F8; }

.b-mselect__list-value { position: absolute; z-index: 13; top: -3px; overflow: hidden; min-width: 221px; min-height: 16px; max-height: 224px; border: 1px solid #CCCCCC; border-radius: 4px; background: #FFFFFF; }

.b-mselect__list-value:focus { outline: 0 none; box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.3); border-color: #78A5DF !important; border-radius: 4px; }

.b-mselect__options { position: absolute; z-index: 99; top: inherit; }

.b-mselect__options.closed { display: none; cursor: default; }

.b-mselect__ul-choose { margin: 0; padding: 0; white-space: normal; }

.b-mselect__ul-view { margin: 0; background: #FFFFFF; white-space: normal; line-height: 8px; border-radius: 4px; padding-top: 0; padding-left: 1px; padding-bottom: 0; padding-right: 0; }

.b-mselect.readonly .b-mselect__ul-view { background: #F7F7F7; }

.b-mselect__unselect { display: inline-block; width: 11px; height: 11px; margin-top: 1px; margin-left: 5px; cursor: pointer; vertical-align: top; background: url("../img/tab_close.png") no-repeat center; }

.b-mselect__ul-choose .b-mselect__unselect { display: none; }

.b-mselect__view-value { display: inline-block; background: #FFFFFF; width: 220px; min-height: 16px; max-height: 140px; overflow: hidden; border: 1px solid #CCCCCC; border-radius: 4px; background: #FFFFFF; }

.b-mselect__view-value:hover { outline: 0 none; box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.3); border-color: #BBB; }

.b-mselect__view-value:focus { outline: 0 none; box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.3); border-color: #78A5DF !important; }

.b-mselect__view-value.vertical-scroll { width: 202px; }

/*Select*/
.b-myselect { position: relative; text-align: left; }

.l-form__row_has_addition .b-myselect { display: inline-block; vertical-align: top; }

.l-form__row_has_addition .b-myselect:first-child { margin-right: 6px; }

.b-myselect a { text-decoration: none; color: #000000; }

.toolbar-elem .b-myselect { display: inline-block; zoom: 1; margin-right: 5px; margin-bottom: 5px; vertical-align: top; *display: inline; }

.row-shadow .b-myselect, .depended-shadow-s .b-myselect, .b-myselect.readonly { color: gray; }

.row-shadow .b-myselect, .depended-shadow-s .b-myselect { background: #F7F7F7; }

.dependelem { display: none; }

li.dependelem.b-myselect__select-li_show_yes { display: list-item; }

.b-myselect__select-ul_searching_true li.dependelem.b-myselect__select-li_show_yes { display: none; }

.dependelem.b-myselect__select-li_show_yes { display: block; }

.b-myselect__item_color_blue { color: #4179CD; }

.b-myselect__item_color_cyan { color: #00B0B2; }

.b-myselect__item_color_green { color: #00A656; }

.b-myselect__item_color_red { color: #E5693C; }

.b-myselect__item_color_yellow { color: #BFA136; }

.b-myselect__img { line-height: 16px; display: inline-block; min-width: 16px; min-height: 16px; max-height: 16px; margin-right: 3px; vertical-align: top; background-repeat: no-repeat; background-position: 0 0; }

li.b-myselect__item-image { height: 18px; }

.b-myselect__search-box { display: none; }

.sb-select .b-myselect__search-box { position: absolute; z-index: 12; top: 0; display: block; min-width: 189px; border-radius: 4px; background: #FFFFFF; }

.l-form__row_has_addition .sb-select .b-myselect__search-box { width: 78px; min-width: 78px; }

.b-myselect_st_close.sb-select .b-myselect__search-box { display: none; }

.b-myselect__option-value { line-height: 16px; display: inline-block; vertical-align: top; max-width: 500px; overflow: hidden; text-overflow: ellipsis; }

.b-myselect__option-wr { position: absolute; }

.visible .b-myselect__option-wr { display: block; visibility: hidden; }

.b-myselect__select-ul_searching_true .b-myselect__select-li { display: none; }

.b-myselect__select-li_clone_true { display: none; }

.b-myselect__select-ul_searching_true .b-myselect__select-li_clone_true { display: block; }

.b-myselect__select-list { position: absolute; z-index: 13; top: 1px; overflow: hidden; min-width: 220px; min-height: 16px; max-height: 224px; border: 1px solid #CCCCCC; border-radius: 4px; background: #FFFFFF; }

.l-form__row_has_addition .b-myselect__select-list { min-width: 106px; }

.b-myselect__select-list li { font-size: 11px; min-width: 205px; height: 16px; padding: 0 7px; list-style: none; cursor: default; white-space: nowrap; }

.l-form__row_has_addition .b-myselect__select-list li { min-width: 92px; }

.b-myselect__select-list li.selected { background: #E1E7ED; }

.b-myselect_st_close .b-myselect__select-list { display: none; }

.b-myselect__select-list ul { margin: 0; padding: 0; background: #FFFFFF; }

.b-myselect__select-list ul li:hover { background: #D0E8F8; }

.b-myselect__select-list ul li.selected:hover { background: #D0E8F8; }

#login-form-form .b-myselect__select-list li { min-width: 116px; }

#login-form-form .b-myselect__select-list { top: -1px; min-width: 100px; }

.toolbar-elem.font-size .b-myselect__select-list, .toolbar-elem.keybinding .b-myselect__select-list { min-width: 57px; }

.toolbar-elem.font-size .b-myselect__select-list li, .toolbar-elem.keybinding .b-myselect__select-list li { min-width: 43px; }

.toolbar-elem.soft-wrap .b-myselect__select-list { min-width: 52px; }

.toolbar-elem.soft-wrap .b-myselect__select-list li { min-width: 52px; }

.toolbar-elem.mode .b-myselect__select-list { min-width: 93px; }

.toolbar-elem.mode .b-myselect__select-list li { min-width: 78px; }

.toolbar-elem .b-myselect__select-list { min-width: 119px; }

.toolbar-elem .b-myselect__select-list li { min-width: 105px; }

.b-myselect_topmenu .b-myselect__select-list { min-width: 120px; }

.b-myselect_topmenu .b-myselect__select-list li { min-width: 98px; }

.b-myselect__select-selected { font-size: 11px; display: inline-block; width: 220px; height: 16px; border-radius: 5px; vertical-align: middle; background: #FFFFFF; }

.l-form__row_has_addition .b-myselect__select-selected { width: 106px; }

.l-form__row_propwidth .b-myselect__select-selected { width: 40px; }

#logon-form .b-myselect__select-selected { width: 130px; }

.toolbar-elem .b-myselect__select-selected { width: 119px; }

.toolbar-elem.font-size .b-myselect__select-selected, .toolbar-elem.keybinding .b-myselect__select-selected { width: 57px; }

.toolbar-elem.soft-wrap .b-myselect__select-selected { width: 51px; }

.toolbar-elem.soft-wrap .b-myselect__select-selected { min-width: 64px; }

.toolbar-elem.mode .b-myselect__select-selected { width: 90px; }

.b-myselect_topmenu .b-myselect__select-selected { width: 120px; height: 18px; }

.b-myselect__select-value { font-size: 11px; line-height: 16px; float: left; overflow: hidden; width: 205px; padding: 0 7px; cursor: default; white-space: nowrap; border: 1px solid #C3C3C3; border-radius: 4px; }

.b-myselect__select-value span { overflow: hidden; max-width: 209px; display: inline-block; vertical-align: top; line-height: 16px; }

.l-form__row_has_addition .b-myselect__select-value { width: 92px; }

.l-form__row_has_addition .b-myselect__select-value span { max-width: 90px; }

.l-form__row_propwidth .b-myselect__select-value { width: 26px; }

.l-form__row_propwidth .b-myselect__select-value span { max-width: 20px; width: 0; }

.b-myselect__select-value input[readonly].b-myselect__select-value { color: #000000; border: 0 none; }

.b-myselect__select-value:focus { outline: 0 none; box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.3); border-color: #78A5DF !important; }

.b-myselect__select-value:hover { outline: 0 none; box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.3); border-color: #BBB; }

#logon-form .b-myselect__select-value { width: 115px; line-height: 15px; }

.b-myselect.readonly .b-myselect__select-value { border-radius: 4px; background: #F7F7F7; }

.b-myselect.b-select_mixed_yes .b-myselect__select-value { border-radius: 4px; color: #CCC; }

.toolbar-elem.font-size .b-myselect__select-value, .toolbar-elem.keybinding .b-myselect__select-value { width: 43px; }

.toolbar-elem.mode .b-myselect__select-value { width: 76px; }

.toolbar-elem.soft-wrap .b-myselect__select-value { width: 51px; }

.toolbar-elem .b-myselect__select-value { width: 106px; }

.b-myselect_topmenu .b-myselect__select-value { line-height: 18px; width: 107px; }

.b-myselect__arrow-down { float: right; width: 13px; height: 14px; margin: 1px; margin-top: -16px; border-radius: 5px; background: #E1E7ED; }

.b-myselect_topmenu .b-myselect__arrow-down { width: 15px; height: 16px; margin-top: -17px; }

.b-myselect__arrow-down.b-myselect__arrow-down_for_mselect { margin: 1px; }

.b-myselect__triangle-down { font-size: 0; line-height: 0; width: 0; height: 0; margin: 6px auto; margin-right: 3px; border-top: 4px solid #879CB5; border-right: 4px solid #E1E7ED; border-left: 4px solid #E1E7ED; }

.b-myselect_topmenu .b-myselect__triangle-down { margin: 7px auto; margin-right: 4px; }

.b-myselect_is_prefix { margin-right: 0 !important; }

.b-myselect_is_prefix .b-myselect__select-value { border-radius: 4px 0 0 4px; border-right: 0 none; }

/*Form frame*/
.b-form-frame { border: 1px solid #CCCCCC; }

/*Tips*/
.b-tip { position: absolute; z-index: 24; padding: 15px 0; cursor: default; }

.tab-content .b-tip { z-index: 19; }

.tab-content.filter-opened .b-tip { z-index: 21; }

.b-tip__inner { position: relative; max-width: 300px; min-height: 20px; margin-right: 16px; padding-bottom: 8px; padding-left: 37px; padding-top: 8px; padding-right: 23px; background-image: url("../../common/img/banner2-icon.png"); background-color: #F8F89F; background-repeat: no-repeat; background-position: 7px 5px; border: 1px solid #C4B154; border-radius: 7px; }

.ie8 .b-tip__inner:before, .ie8 .b-tip__inner:after { content: none; border: 0 none; }

.b-tip__inner:before { position: absolute; width: 0; height: 0; content: ''; }

.b-tip__inner:after { position: absolute; width: 0; height: 0; content: ''; }

.b-tip__close { width: 11px; height: 11px; background-image: url("../img/sprite-icons.png"); background-repeat: no-repeat; background-position: 0px -31px; position: absolute; top: 20px; right: 20px; cursor: pointer; z-index: 1; }

.b-useful-links .b-tip__close { top: 5px; }

.b-useful-links_is_collapsed .b-tip__close { display: none; }

/*Notify numbers*/
.b-number { background-image: url("../img/smalldigit.png"); background-repeat: no-repeat; width: 3px; height: 5px; display: inline-block; margin-right: 1px; }

.b-number:first-child { margin-left: 1px; }

.b-number_value_0 { background-position: -36px 0; }

.b-number_value_1 { background-position: 0 0; }

.b-number_value_2 { background-position: -4px 0; }

.b-number_value_3 { background-position: -8px 0; }

.b-number_value_4 { background-position: -12px 0; }

.b-number_value_5 { background-position: -16px 0; }

.b-number_value_6 { background-position: -20px 0; }

.b-number_value_7 { background-position: -24px 0; }

.b-number_value_8 { background-position: -28px 0; }

.b-number_value_9 { background-position: -32px 0; }

/*Fast filter icon*/
.b-fast-filter-icon { position: absolute; z-index: 1; display: none; width: 10px; height: 22px; cursor: pointer; /*img from sprite*/ background-image: url(../img/sprite-icons.png); background-repeat: no-repeat; background-position: 0px -86px; }

.b-fast-filter-icon.active { display: block; }

/*Resizer for form controls*/
.b-resizer { width: 100%; height: 5px; cursor: n-resize; background: #E1E7ED; bottom: -5px; margin-left: 15px; }

.b-resizer:before { position: absolute; width: 9px; height: 5px; content: ''; background: #FFFFFF; }

.edit-field-form .b-resizer { display: none; }

.b-resizer_for_editor { display: none; }

.b-resizer_for_textarea { bottom: -3px; }

.formwidth-row .b-resizer_for_textarea { margin-left: -16px; padding-right: 21px; }

.l-form__col_type_fullwidth .b-resizer_for_textarea { bottom: -5px; }

.b-resizer_for_textarea:after { position: absolute; content: ""; background: #FFFFFF; height: 5px; width: 2px; right: 0; }

/*Infolist in dashboard*/
.b-infolist__col { line-height: 22px; padding-left: 14px; vertical-align: middle; }

.b-infolist__col_type_header { color: #6086AB; font-weight: bold; line-height: 25px; height: 25px; text-align: left; vertical-align: top; border-right: 1px; padding-left: 14px; }

.b-infolist__row_type_header { background: #EDF2F7; }

.b-infolist__table { border-spacing: 0; }

/*Product block*/
.b-product { color: #595959; margin-bottom: 5px; }

/*Table list*/
.b-list__table-col-content_is_wrap { white-space: pre-wrap !important; }

.b-list__table-row:hover { background: #D0E8F8; }

/*Login links*/
.b-login-links { text-align: center; margin-top: 13px; margin-bottom: 13px; }

.b-login-links__item { display: inline-block; }

/*Copyright*/
.b-copyright a { text-decoration: none; }

/*Fatal error*/
.b-alert { display: block; }

.b-alert__icon { float: left; margin-right: 16px; }

.b-alert__icon_for_group { margin-left: 10px; margin-top: 10px; display: none; }

.modal-with-warning .b-alert__icon_for_group { display: block; position: absolute; top: 50%; margin-top: -28px; }

.b-alert__sign { background: #FF8356; width: 40px; height: 40px; border-radius: 40px; padding: 8px; }

.b-alert__inner_t_refresh-link .b-alert__sign { background-color: #FFD243; }

.b-alert__sign-top { width: 8px; height: 4px; margin: 0 auto; border-radius: 28px 28px 0 0; background: white; }

.b-alert__sign-body { width: 0; height: 0; margin: 0 auto; content: ""; border-width: 27px 4px 0 4px; border-style: solid outset; border-color: white transparent transparent transparent; }

.b-alert__sign-empty { z-index: 1; width: 5px; height: 6px; margin: 0 auto; margin-top: -7px; background: #FF8356; }

.b-alert__inner_t_refresh-link .b-alert__sign-empty { background-color: #FFD243; }

.b-alert__sign-dot { width: 7px; height: 7px; margin: 0 auto; margin-top: 0; border-radius: 7px; background: white; }

.b-alert__clear { clear: both; }

.b-alert__msg { height: 56px; display: table-cell; vertical-align: middle; }

.b-alert__msg-outter { max-height: 100px; overflow-y: auto; overflow-x: hidden; text-overflow: ellipsis; }

/*Ticket message templates*/
.b-msg-templ { position: absolute; background: #FFFFFF; border: 1px solid #CCCCCC; z-index: 3; bottom: 0px; max-width: 500px; border-radius: 4px; }

.b-msg-templ__title-content-item { display: none; padding: 2px 7px; }

.b-msg-templ__title-list-item { padding-left: 2px; padding-top: 2px; padding-bottom: 2px; text-decoration: underline; cursor: pointer; white-space: nowrap; color: #537393; display: inline-block; }

.b-msg-templ__title-list-item:first-child { padding-left: 7px; }

.b-msg-templ__title-list-item:last-child { padding-right: 7px; }

.b-msg-templ__title-list-item:hover { text-decoration: none; }

.b-msg-templ__title-list-item_is_comma { text-decoration: none; color: #000; padding-left: 0; }

.b-msg-templ__table-col { vertical-align: top; }

.b-msg-templ__options { position: relative; }

.b-msg-templ__title-list { padding-bottom: 3px; }

.testmode__form-link { display: inline-block; background-image: url("../img/tick.png"); background-repeat: no-repeat; background-position: 0 0; height: 16px; width: 16px; }

.testmode__list-icon { height: 16px; width: 16px; background-image: url("../img/tick.png"); background-repeat: no-repeat; background-position: 0 2px; position: absolute; }

/* Useful link in form & list*/
.b-useful-links { position: absolute; top: 40px; right: -16px; z-index: 3; }

.list-content .b-useful-links { top: 95px; right: 0; }

.b-useful-links .b-tip__inner { background-image: url(../../common/img/p-info.png); background-position: 8px 6px; padding-left: 29px; }

.b-useful-links-flag_is_collapsed .b-useful-links { display: none; }

.b-useful-links-flag_is_collapsed .b-useful-links .b-tip__inner { padding-right: 10px; padding-bottom: 5px; min-height: 10px; }

a.b-usefulLinks__icon { display: none; }

.b-useful-links-flag_is_collapsed .tab-content_has_usefullinks a.b-usefulLinks__icon { display: inline-block; }

.b-useful-links__title { font-weight: bold; padding-bottom: 5px; color: #444; }

.b-useful-links__content a { text-decoration: none; display: block; padding-bottom: 4px; padding-left: 3px; }

.b-useful-links_is_collapsed .b-useful-links__content { display: none; }

/* Links on login form */
.b-login-bottom-links { display: block; text-align: center; position: absolute; bottom: 20px; width: 100%; left: 0; }

.b-login-bottom-links__item { display: inline-block; margin-right: 10px; line-height: 20px; }

/* form item Link */
.l-form__row_has_addition .b-form-link { display: inline-block; min-width: 108px; line-height: 18px; }

.l-form__row_has_addition.l-form__row_type_textdata .b-form-link { display: inline; }

/* Form item Datetime */
.b-form-datetime { min-width: 222px; }

/* Right user menu */
.b-user-menu { display: none; position: absolute; right: -1px; min-width: 100%; z-index: 99; text-align: left; padding-top: 5px; background-color: #FFF; border: 1px solid #E1E7ED; border-radius: 10px 0px 10px 10px; }

.b-top-links__item_user_menu_has_item:hover .b-user-menu { display: block; }

.b-user-menu:hover { display: block; }

.b-top-links__item_user_menu { position: relative; border-left: 1px solid #FFF; border-right: 1px solid #FFF; margin-right: 14px; padding-right: 8px; padding-left: 11px; }

.b-top-links__item_user_menu.b-top-links__item_user_menu_has_item:hover { border-left: 1px solid #E1E7ED; border-right: 1px solid #E1E7ED; }

.b-top-links__item_user_menu.b-top-links__item_user_menu_has_item:hover .b-user-menu__make-up-helper-1 { height: 8px; width: 100%; position: absolute; z-index: 100; border-right: 1px solid #E1E7ED; right: -1px; background-color: #FFF; }

.b-top-links__item_user_menu.b-top-links__item_user_menu_has_item:hover .b-user-menu__make-up-helper-2 { height: 8px; width: 10px; position: absolute; z-index: 98; left: -1px; background-color: #FFF; border-left: 1px solid #E1E7ED; }

.b-top-links__item_user_menu .triangledown { display: none; }

.b-top-links__item_user_menu_has_item .triangledown { display: inline-block; }

.b-user-menu__item { padding: 2px 10px; white-space: nowrap; }

.b-user-menu__item_separate_yes { padding: 0; border-top: 1px solid #CCC; }

.b-user-menu__header { cursor: default; }

/* Notif bar */
.b-notif__img { z-index: -1; }

.b-notif__text { font-size: 8px; line-height: 11px; position: absolute; z-index: 1; min-width: 7px; height: 11px; margin-top: -6px; margin-left: 7px; padding: 0 2px; text-align: center; color: #FFFFFF; border-radius: 12px; background: #1FB02C; }

.b-notif__elem { display: inline-block; height: 20px; margin-left: 11px; margin-bottom: 6px; margin-right: 0px; margin-top: 3px; cursor: pointer; }

.b-notif__elem:first-child { margin-right: 11px; margin-left: 13px; }

.b-notif__elem:nth-child(2) { margin-left: 0; }

.b-notif__elem:last-child { margin-right: 13px; }

.b-gsearch__list { margin-top: -7px; margin-left: 13px; }

.b-gsearch__input_loading_yes { background-image: url("../img/loader.gif"); background-repeat: no-repeat; background-position: right center; }

.b-gsearch__list-item.b-gsearch__list-item_selected_yes { background-color: #D0E8F8; }

.b-gsearch__close { position: absolute; z-index: 1; right: 25px; top: 9px; width: 11px; height: 11px; display: none; background-image: url("../img/sprite-icons.png"); background-position: 0px -185px; background-repeat: no-repeat; }

.b-gsearch__close:hover { background-position: 0 0; }

.b-gsearch__close_is_show { display: block; }

.b-notify-banner { background-color: #E1E7ED; display: none; border-radius: 4px; border: 1px solid #BCD2E7; margin-bottom: 5px; margin-top: 5px; padding: 5px 10px; padding-right: 5px; max-width: 300px; min-width: 100px; }

.b-notify-banner__list_is_open .b-notify-banner { display: block; }

.b-notify-banner.b-notify-banner_is_new { display: block; }

.b-notify-banner__wrapper { position: absolute; z-index: 99; top: 0; left: 50%; }

.b-notify-banner__close { float: right; cursor: pointer; }

.b-notify-banner__close-all { text-align: right; display: none; margin-right: 5px; }

.b-notify-banner__list_is_open .b-notify-banner__close-all { display: block; }

.b-notify-banner__close-all.b-notify-banner_is_new { display: block; }

.b-notify-banner__close-all-inner { display: inline-block; cursor: pointer; }

.b-notify-banner__list { position: absolute; z-index: 100; min-width: 250px; }

.b-notify-banner_status_1 { background-color: #FF8356; }

.b-notify-banner_status_2 { background-color: #FFD243; border-color: #EAC85D; }

.b-notify-banner_status_3 { background-color: #A9F1BA; border-color: #93DBA4; }

.b-pin { background-image: url("../../common/img/pin.png"); background-repeat: no-repeat; background-position: 0 0; width: 16px; height: 16px; cursor: pointer; display: inline-block; vertical-align: bottom; margin-bottom: 2px; margin-left: 10px; }

.b-leftbar { white-space: nowrap; }

.b-leftbar h2 { overflow: hidden; text-overflow: ellipsis; width: 100%; }

.b-leftbar_for_list { margin-right: 300px; }

.b-leftbar_for_form { margin-right: 146px; }

.b-leftbar_for_map { margin-right: 146px; }

.b-captcha { text-align: center; }

.b-captcha__inner { display: inline-block; }
